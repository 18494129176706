body.lang-zh-hans {
    font-family: $FONT_FAMILY_BODY_SC;
    --font-family-body: #{$FONT_FAMILY_BODY_SC};
    --font-family-title: #{$FONT_FAMILY_TITLE_SC};
    --font-family-form: #{$FONT_FAMILY_FORM_SC};
    --font-family-button: #{$FONT_FAMILY_BUTTON_SC};
    h1,h2,h3,h4,h5,h6 {
        font-family: $FONT_FAMILY_TITLE_SC;
    }
    textarea, input {
        font-family: $FONT_FAMILY_BODY_SC;
    }
}

body.lang-zh-hant {
    font-family: $FONT_FAMILY_BODY_TC;
    --font-family-body: #{$FONT_FAMILY_BODY_TC};
    --font-family-title: #{$FONT_FAMILY_TITLE_TC};
    --font-family-form: #{$FONT_FAMILY_FORM_TC};
    --font-family-button: #{$FONT_FAMILY_BUTTON_TC};
    h1,h2,h3,h4,h5,h6 {
        font-family: $FONT_FAMILY_TITLE_TC;
    }
    textarea, input {
        font-family: $FONT_FAMILY_BODY_TC;
    }
}

body.lang-ja {
    font-family: $FONT_FAMILY_BODY_JP;
    --font-family-body: #{$FONT_FAMILY_BODY_JP};
    --font-family-title: #{$FONT_FAMILY_TITLE_JP};
    --font-family-form: #{$FONT_FAMILY_FORM_JP};
    --font-family-button: #{$FONT_FAMILY_BUTTON_JP};
    h1,h2,h3,h4,h5,h6 {
        font-family: $FONT_FAMILY_TITLE_JP;
    }
    textarea, input {
        font-family: $FONT_FAMILY_BODY_JP;
    }
}

body.lang-ko {
    font-family: $FONT_FAMILY_BODY_KR;
    --font-family-body: #{$FONT_FAMILY_BODY_KR};
    --font-family-title: #{$FONT_FAMILY_TITLE_KR};
    --font-family-form: #{$FONT_FAMILY_FORM_KR};
    --font-family-button: #{$FONT_FAMILY_BUTTON_KR};
    h1,h2,h3,h4,h5,h6 {
        font-family: $FONT_FAMILY_TITLE_KR;
    }
    textarea, input {
        font-family: $FONT_FAMILY_BODY_KR;
    }
}
