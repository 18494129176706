@mixin futureFont(){
    font-family: "Noto Sans", Arial, Helvetica, sans-serif;
}

@mixin notoSansFont() {
    font-family: "Noto Sans", Arial, Helvetica, sans-serif;
}

@mixin transform50($child){
    transform: skewX(-50deg);
    #{$child}{
        transform: skewX(50deg);
    }
}

@mixin button($wrapEl, $color: $pcRed) {
    background: $color;
    border: none !important;
    color: #fff !important;
    font-size: 14px;
    font-weight: lighter;
    display: inline-block;
    padding: 0 35px;
    height: 38px;
    line-height: 38px;
    text-transform: uppercase;
    border-radius: 0px;
    @include futureFont;

    #{$wrapEl}{
        display: block;
    }
}

//Use this for creating angled sides when skewing isn't enough.
//http://codepen.io/ricardozea/pen/vqckl
@mixin corner-triangle($corner, $size, $color) {
    aspect-ratio: 1;

    @if $corner == 'top-left' {
        top: 0;
        bottom: 0;
        right: 100%;
        border-top: $size solid $color;
        border-right: $size solid transparent;
    } @else if $corner == 'top-right' {
        top: 0;
        bottom: 0;
        left: 100%;
        border-top: $size solid $color;
        border-left: $size solid transparent; 
    } @else if $corner == 'bottom-left' {
        top: 0;
        bottom: 0;
        left: 100%;
        border-right: $size solid transparent;
        border-bottom: $size solid $color;
    } @else if $corner == 'bottom-right' {
        top: 0;
        bottom: 0;
        right: 100%;
        border-bottom: $size solid $color;
        border-left: $size solid transparent;
    }
}

@mixin corner-triangle-bookends($size, $color) {
    &::before, &::after{
        content: '';
        position: absolute;
    }

    &::before {
        @include corner-triangle('bottom-right', $size, $color);
    }

    &::after {
        @include corner-triangle('bottom-left', $size, $color);
    }
}

//Add "position: relative" to the element's parent when using this.
@mixin horizontal-align-absolute() {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
}

@mixin mq($point) {
    @if $point == hd {
        @media (min-width: 1980px) { @content; }
    }
    @else if $point == tablet {
        @media (max-width: 875px) { @content; }
    }
    @else if $point == mobilelandscape {
      @media (max-width: 812px) and (orientation: landscape)  { @content; }
    }
    @else if $point == mobile {
      @media (max-width: 600px) { @content; }
    }
    @else if $point == ib {
        @media (min-width: 875px) and (max-width: 1250px)  { @content; }
    }
    @else if $point == steambrowser{
        @media (max-width: 1250px) and (min-width: 600px){ @content; }
    }
    @else if $point == notTabletOrMobile {
      @media (min-width: 876px)  { @content; }
    }
}

// Some mobile-first helpers for transitional use
@mixin mq-mobile-only {
    @media (max-width: 600px) { @content; }
}
@mixin mq-tablet-up {
    @media (min-width: 601px) { @content; }
}
@mixin mq-tablet-only {
    @media (min-width: 601px) and (max-width: 875px) { @content; }
}
@mixin mq-ipad-up {
    @media (min-width: 768px) { @content; }
}
@mixin mq-ipad-not {
    @media (max-width: 767px) { @content; }
}

@mixin mq-tablet-down {
    @media (max-width: 875px) { @content; }
}
@mixin mq-desktop-up {
    @media (min-width: 876px) { @content; }
}
@mixin mq-desktop-only {
    @media (min-width: 876px) and (max-width: 1600px) { @content; }
}
@mixin mq-wide-up {
    @media (min-width: 1601px) { @content; }
}



@mixin volt_bg(){
    .wrapper {
        background-image: url('../images/account/volt_BG.jpg');
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center;
        background-position-y: 64px;

        @include mq(tablet){
            background-position-y: 129px;
        }

        @include mq(mobile) {
            background-image: none;
        }

        @include mq(hd) {
          background-size:100%;
        }
    }
}

@mixin warframe-payment-tab(){
    &.xsolla-1380{
        background-image: url('../images/paymenticons/creditCards.png');
    }
    &.steam{
        background-image: url('../images/paymenticons/steam.png');
    }
    &.rixty{
        background-image: url('../images/paymenticons/rixty.png');
    }
    &.xsolla-24{
        background-image: url('../images/paymenticons/paypal.png');
    }
    &.molpay{
        background-image: url('../images/paymenticons/molpoints.png');
    }
    &.xsolla-1497{
        background-image: url('../images/paymenticons/MisterCash.png');
    }
    &.xsolla-57{
        background-image: url('../images/paymenticons/paysafe.png');
    }
    &.xsolla-1944{
        background-image: url('../images/paymenticons/boleto.png');
    }
    &.xsolla-836{
        background-image: url('../images/paymenticons/interac-online.png');
    }
    &.xsolla-81{
        background-image: url('../images/paymenticons/DaoPay.png');
    }
    &.xsolla-1496{
        background-image: url('../images/paymenticons/ideal.png');
    }
    &.xsolla-1909{
        background-image: url('../images/paymenticons/przelewy24.png');
    }
    &.xsolla-16{
        background-image: url('../images/paymenticons/qiwi.png');
    }
    &.xsolla-2667{
        background-image: url('../images/paymenticons/au_commonwealth.png');
    }
    &.xsolla-2140{
        background-image: url('../images/paymenticons/at_sofort.png');
    }
    &.xsolla-1498{
        background-image: url('../images/paymenticons/at_eps.png');
    }
    &.xsolla-465{
        background-image: url('../images/paymenticons/skrill.png');
    }        
    &.xsolla-2031{
        background-image: url('../images/paymenticons/rbc.png');
    }       
    &.xsolla-2130{
        background-image: url('../images/paymenticons/bci.png');
    }       
    &.xsolla-2134{
        background-image: url('../images/paymenticons/bancoestado.png');
    }
    &.xsolla-2133{
        background-image: url('../images/paymenticons/bancodechile.png');
    }
    &.xsolla-311,&.xsolla-3323,&.xsolla-3585,&.xsolla-3624,&.xsolla-3623 {
        background-image: url('../images/paymenticons/alipay.png');
    }
    &.xsolla-1026{
        background-image: url('../images/paymenticons/tenpay.png');
    }
    &.xsolla-2580{
        background-image: url('../images/paymenticons/upop.png');
    }
    &.xsolla-3215{
        background-image: url('../images/paymenticons/wechat.png');
    }
    &.xsolla-3172{
        background-image: url('../images/paymenticons/giropay.png');
    }
    &.xsolla-1148{
        background-image: url('../images/paymenticons/trustly.png');
    }
    &.xsolla-1806{
        background-image: url('../images/paymenticons/nordea.png');
    }    
    &.xsolla-3325{
        background-image: url('../images/paymenticons/alfamart.png');
    }    
    &.xsolla-3047{
        background-image: url('../images/paymenticons/oxxo.png');
    }    
    &.xsolla-1687{
        background-image: url('../images/paymenticons/maybank.png');
    }
    &.xsolla-1682{
        background-image: url('../images/paymenticons/cimb.png');
    }
    &.xsolla-2669{
        background-image: url('../images/paymenticons/kiwibank.png');
    }
    &.xsolla-2419{
        background-image: url('../images/paymenticons/2419.png');
    }
    &.xsolla-1920{
        background-image: url('../images/paymenticons/mbank.png');
    }
    &.xsolla-3173{
        background-image: url('../images/paymenticons/multibanco.png');
    }
    &.xsolla-291{
        background-image: url('../images/paymenticons/sberbank.png');
    }
    &.xsolla-1336{
        background-image: url('../images/paymenticons/webmoney.png');
    }
    &.xsolla-235{
        background-image: url('../images/paymenticons/amazonpay.png');
    }
    &.xsolla-2532{
        background-image: url('../images/paymenticons/epay.png');
    }
    &.xsolla-2097{
        background-image: url('../images/paymenticons/sparkasse.png');
    }
    &.xsolla-1810{
        background-image: url('../images/paymenticons/swedbank.png');
    }
    &.xsolla-2928{
        background-image: url('../images/paymenticons/skt.png');
    }
    &.xsolla-2929{
        background-image: url('../images/paymenticons/lgu+.png');
    }
    &.xsolla-27{
        background-image: url('../images/paymenticons/Yandex_money.png');
    }
    &.xsolla-1852,&.xsolla-390,&.xsolla-1750{
        background-image: url('../images/paymenticons/mycard.png');
    }
    //&.dRiver-cpgVisaDelta,&.dRiver-cpgVisaElectron{
    //    background-image: url('../images/paymenticons/visa-delta-debit.png');
    //}
    &.xsolla-749{
        background-image: url('../images/paymenticons/gudang.png');
    }
    &.xsolla-132{
        background-image: url('../images/paymenticons/cashu.png');
    }
    &.xsolla-226{
        background-image: url('../images/paymenticons/toditocash.png');
    }
    &.xsolla-250{
        background-image: url('../images/paymenticons/onecard.png');
    }
    &.xsolla-2601{
        background-image: url('../images/paymenticons/poli.png');
    }
    &.xsolla-6{
        background-image: url('../images/paymenticons/webmoney.png');
    }
    &.xsolla-1859{
        background-image: url('../images/paymenticons/obucks.png');
    }
    &.karmakoin{
        background-image:url('../images/paymenticons/nexongamecard.png');
        background-size: contain;
    }
    &.ycoins{
        background-image:url('../images/paymenticons/ycoins2.png');
    }
    &.xsolla-2683{
        background-image:url('../images/paymenticons/paygarden.png');
    }
    &.xsolla-Other{
        font-weight: bold;
    }
    //&.xsolla-1711{
    //    background-image:url('../images/paymenticons/xsolla-1711.png');
    //}
    //&.xsolla-1730{
    //    background-image:url('../images/paymenticons/xsolla-1730.png');
    //}
    &.xsolla-606{
        background-image:url('../images/paymenticons/privatbank.png');
    }
    &.xsolla-1497{
        background-image:url('../images/paymenticons/MisterCash.png');
    }
    &.xsolla-3312{
        background-image:url('../images/paymenticons/blik.png');
    }
    &.xsolla-3364{
        background-image:url('../images/paymenticons/zimpler.png');
    }
    &.xsolla-2712{
        background-image:url('../images/paymenticons/sepa.png');
    }
    &.xsolla-2712{
        background-image:url('../images/paymenticons/sepa.png');
    }
    &.xsolla-2883{
        background-image:url('../images/paymenticons/nordea.png');
    }
}

@mixin popup-payment-selector(){
    #colorbox.paymentBox #cboxContent{
        background: #fff !important;

        .paymentMethods{
            background-color: white;
            padding: 20px 0;
            text-align: center;

            .selecta{
                margin: 15px 0;
                font-weight: bold;
                color: #101010;
            }

            .legal-note{
                font-size: 16px;
                line-height: 24px;
                color: #1D5272;
                margin: 40px 60px 20px;

                @include mq-mobile-only {
                    margin: 40px 30px 20px;
                }

                a {
                    text-decoration: underline;
                }
            }

            img{
                margin-bottom: 20px;
            }

            .method{
                border-radius: 3px;
                box-shadow: inset 0px 1px 3px rgba(000,000,000,0.12);
                display: inline-block;
                cursor: pointer;
                background-color: #E4E4E4;
                background-position: center;
                padding: 5px;
                width: 85px;
                height: 55px;
                background-repeat: no-repeat;
                margin: 5px;
                vertical-align: top;
                border:none;
                min-width: inherit;

                @include warframe-payment-tab();

                &.xsolla-Other{
                    font-size: 16px;
                }
                .paymentOther {
                    color: #686868;
                    padding-top: 5px;
                    font-size: 14px;
                }
            }

            &.noSteam{
                .method[data-gateway="xsolla-Other"]{
                    display: inline-block;
                }
            }
        }
    }
}

@mixin fullscreen-video-background{
    position: fixed;
    min-height: 100%;
    width: 100%;
    right: 0;
    margin: 0 auto;
    z-index: 1;
    top: 0;
    object-fit: cover;
}

//Centers horizontall / vertically
@mixin center($horizontal: true, $vertical: true) {
    position: absolute;
    @if ($horizontal and $vertical) {
        top: 50%;
        left: 50%;
        //@include transform(translate(-50%, -50%));
        transform: translate(-50%, -50%);
    } @else if ($horizontal) {
        left: 50%;
        //@include transform(translate(-50%, 0));
        transform: translate(-50%, 0);
    } @else if ($vertical) {
        top: 50%;
        //@include transform(translate(0, -50%));
        transform: translate(0, -50%);
    }
}

@mixin platform-selector{

    .crossSaveSignupHeader {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        div {
            margin: 8px 0;
        }
        .crossSaveSignupDesc {
            max-width: 70%;
            width: fit-content;
        }
    }
    #consoleSignup {

        .divider {
            display: flex;
            align-items: center;
            text-align: center;
            color: #A9A9A9;
            position: relative;

            &::before,
            &::after {
                content: "";
                flex: 1;
                border-top: 1px solid #A9A9A9;
            }
        }
        .h4 {
            padding: 0 30px;
            position: relative;
            z-index: 1;
        }

        .h4, .h3 {
            margin: 16px 0;
        }
    }
    #signupPlatforms, #signupOtherPlatforms{
        display: grid;
        grid-template-columns: repeat(2, minmax(320px, 1fr));
        grid-column-gap: 8px;
        grid-row-gap: 8px;
        justify-content: center;
        max-width: 800px;
        margin: 0 auto;

        &.single {
            display: flex;

            .pc {
                background: none;
            }
        }

        @include mq-mobile-only {
            grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
        }
        /*
        @media (min-width: 1100px) {
            grid-template-columns: repeat(3, minmax(320px, 1fr));
        }
        */

    }

    .pcSignup{
        display: none;

        max-width: 600px;
        margin: 64px auto 0;
    }
}

@mixin fullscreen-content-page{
    #bgvid,#vidFallback{
        @include fullscreen-video-background;
    }

    #mainText{
        position: relative;
        z-index: 10;
    }

    #mainText {

        .text-content{
            text-align: center;
            color:#fff;
            padding-bottom: 20px;

            .title{
                font-size: 36px;
                padding: 30px;
                text-shadow: 0px 0px 40px #fff;
                //font-family: $notoSansFont;
                text-transform: uppercase;
            }

            .contactImg{
                margin: 25px 0;
            }

            .contentHeaderContain{
                width:75%;

                .contentHeaderLines{
                    border-top: 1px solid #fff;
                    border-bottom: 1px solid #fff;
                    height: 6px;
                }

                .contentHeaderText{
                    font-size: 21px;
                    top: -10px;
                    position: relative;
                }
            }

            p{
                width: 65%;
                margin: 0 auto;
                font-size: 20px;
                margin-bottom: 25px;
                line-height: 25px;
            }
        }
    }
}

@mixin no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.clearfix { display: inline-block; }
* html .clearfix { height: 1%; }
.clearfix { display: block; }

@mixin arrowIcon($dir, $size, $color) {
    width: 0;
    height: 0;
    @if($dir == up) {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-bottom: $size solid $color;
    } @else if($dir == down) {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-top: $size solid $color;
    } @else if($dir == left) {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-right: $size solid $color;
    } @else if($dir == right) {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
    }
}

@mixin animatingButton() {
    position: relative;
    transition: filter .5s linear;
    overflow: hidden;

    &:before {
        z-index: -1;
        content: '';
        position: absolute;
        left: 0;
        right: -50px;
        top: 0;
        border-right: 20px solid transparent;
        border-bottom: 80px solid rgba(255, 255, 255, 0.2);
        transform: translateX(-100%);
        transition: transform .5s linear, opacity .5s linear;
        pointer-events: none;
    }

    &:hover {
        filter: drop-shadow(0px 0px 15px rgba(255, 255, 255, .3));

        &:before {
            transform: translateX(0);
        }
    }

    &.disabled {
        &:before {
            display: none;
        }
        &:hover {
            filter: none;
        }
    }
}
