@keyframes Carousel-rotating-left-0 {
    from {
        left: 10%;
        opacity: 0.5;
        transform: translate(-50%, -50%) scale(0.5);
    }
    to {
        left: 0;
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.3);
    }
}
@keyframes Carousel-rotating-left-1 {
    from {
        left: 30%;
        opacity: 0.75;
        transform: translate(-50%, -50%) scale(0.75);
    }
    to {
        left: 10%;
        opacity: 0.5;
        transform: translate(-50%, -50%) scale(0.5);
    }
}
@keyframes Carousel-rotating-left-2 {
    from {
        left: 50%;
        opacity: 1.0;
        transform: translate(-50%, -50%) scale(1);
    }
    to {
        left: 30%;
        opacity: 0.75;
        transform: translate(-50%, -50%) scale(0.75);
    }
}
@keyframes Carousel-rotating-left-3 {
    from {
        left: 70%;
        opacity: 0.75;
        transform: translate(-50%, -50%) scale(0.75);
    }
    to {
        left: 50%;
        opacity: 1.0;
        transform: translate(-50%, -50%) scale(1);
    }
}
@keyframes Carousel-rotating-left-4 {
    from {
        left: 90%;
        opacity: 0.5;
        transform: translate(-50%, -50%) scale(0.5);
    }
    to {
        left: 70%;
        opacity: 0.75;
        transform: translate(-50%, -50%) scale(0.75);
    }
}
@keyframes Carousel-rotating-left-5 {
    from {
        left: 100%;
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.3);
    }
    to {
        left: 90%;
        opacity: 0.5;
        transform: translate(-50%, -50%) scale(0.5);
    }
}
@keyframes Carousel-rotating-right-0 {
    from {
        left: 0;
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.3);
    }
    to {
        left: 10%;
        opacity: 0.5;
        transform: translate(-50%, -50%) scale(0.5);
    }
}
@keyframes Carousel-rotating-right-1 {
    from {
        left: 10%;
        opacity: 0.5;
        transform: translate(-50%, -50%) scale(0.5);
    }
    to {
        left: 30%;
        opacity: 0.75;
        transform: translate(-50%, -50%) scale(0.75);
    }
}
@keyframes Carousel-rotating-right-2 {
    from {
        left: 30%;
        opacity: 0.75;
        transform: translate(-50%, -50%) scale(0.75);
    }
    to {
        left: 50%;
        opacity: 1.0;
        transform: translate(-50%, -50%) scale(1);
    }
}
@keyframes Carousel-rotating-right-3 {
    from {
        left: 50%;
        opacity: 1.0;
        transform: translate(-50%, -50%) scale(1);
    }
    to {
        left: 70%;
        opacity: 0.75;
        transform: translate(-50%, -50%) scale(0.75);
    }
}
@keyframes Carousel-rotating-right-4 {
    from {
        left: 70%;
        opacity: 0.75;
        transform: translate(-50%, -50%) scale(0.75);
    }
    to {
        left: 90%;
        opacity: 0.5;
        transform: translate(-50%, -50%) scale(0.5);
    }
}
@keyframes Carousel-rotating-right-5 {
    from {
        left: 90%;
        opacity: 0.5;
        transform: translate(-50%, -50%) scale(0.5);
    }
    to {
        left: 100%;
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.3);
    }
}

.Carousel {
    position: relative;
    overflow: visible;

    &-wrapper {
        position: relative;
    }
    &-content {
        position: relative;
        overflow-y: visible;
    }
    &-controls {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        pointer-events: none;
        cursor: pointer;
    }
    &-prev,
    &-next {
        position: absolute;
        top: 50%;
        pointer-events: auto;
        transform: translateY(-50%);
    }
    &-prev {
        left: 0;
    }
    &-next {
        right: 0;
    }

    &--rotating, &--single {
        $HEIGHT_SLIDE_MOBILE: 0.28125 * $BREAKPOINT_MOBILE;
        $HEIGHT_SLIDE_TABLET: 0.28125 * $BREAKPOINT_TABLET;
        $HEIGHT_SLIDE_DESKTOP: 0.28125 * $BREAKPOINT_DESKTOP;
        $HEIGHT_SLIDE_WIDE: 0.28125 * $BREAKPOINT_WIDE;

        .Carousel-content {
            height: $HEIGHT_SLIDE_MOBILE;

            @include breakpoint-tablet-up {
                height: $HEIGHT_SLIDE_TABLET;
            }
            @include breakpoint-desktop-up {
                height: $HEIGHT_SLIDE_DESKTOP;
            }
            @include breakpoint-wide-up {
                height: $HEIGHT_SLIDE_WIDE;
            }
        }
        .Carousel-prev {
            left: auto;
            right: 80%;
        }
        .Carousel-next {
            left: 80%;
            right: auto;
        }

        .CarouselSlide {
            display: none;
            position: absolute;
            top: 50%;

            aspect-ratio: 16/9;
            height: $HEIGHT_SLIDE_MOBILE;
            opacity: 1.0;
            transform: translate(-50%, -50%) scale(1);

            overflow: visible;

            @include breakpoint-tablet-up {
                height: $HEIGHT_SLIDE_TABLET;
            }
            @include breakpoint-desktop-up {
                height: $HEIGHT_SLIDE_DESKTOP;
            }
            @include breakpoint-wide-up {
                height: $HEIGHT_SLIDE_WIDE;
            }

            &.is-prev,
            &.is-next {
                display: block;
                opacity: 0.5;
            }

            &.is-prev {
                left: 0;
            }
            &.is-next {
                left: 100%;
            }

            &.is-active {
                display: block;
                left: 50%;
                opacity: 1.0;
                &.is-animating {
                    &Left {
                        animation: Carousel-rotating-left-2, Carousel-rotating-left-1;
                        animation-duration: 250ms, 250ms;
                        animation-fill-mode: forwards, forwards;
                        animation-timing-function: ease-in, ease-out;
                        animation-delay: 0ms, 250ms;
                    }
                    &Right {
                        animation: Carousel-rotating-right-3, Carousel-rotating-right-4;
                        animation-duration: 250ms, 250ms;
                        animation-fill-mode: forwards, forwards;
                        animation-timing-function: ease-in, ease-out;
                        animation-delay: 0ms, 250ms;
                    }
                }
            }
            &.is-prev {
                display: block;
                left: 10%;
                opacity: 0.5;
                transform: translate(-50%, -50%) scale(0.5);
                &.is-animating {
                    &Left {
                        animation: Carousel-rotating-left-0 250ms forwards ease-in;
                        &.is-wraparound {
                            animation: Carousel-rotating-left-0, Carousel-rotating-left-5;
                            animation-duration: 250ms, 250ms;
                            animation-fill-mode: forwards, forwards;
                            animation-timing-function: ease-in, ease-out;
                            animation-delay: 0ms, 250ms;
                        }
                    }
                    &Right {
                        animation: Carousel-rotating-right-1, Carousel-rotating-right-2;
                        animation-duration: 250ms, 250ms;
                        animation-fill-mode: forwards, forwards;
                        animation-timing-function: ease-in, ease-out;
                        animation-delay: 0ms, 250ms;
                    }
                }
            }
            &.is-next {
                display: block;
                left: 90%;
                opacity: 0.5;
                transform: translate(-50%, -50%) scale(0.5);
                &.is-animating {
                    &Left {
                        animation: Carousel-rotating-left-4, Carousel-rotating-left-3;
                        animation-duration: 250ms, 250ms;
                        animation-fill-mode: forwards, forwards;
                        animation-timing-function: ease-in, ease-out;
                        animation-delay: 0ms, 250ms;
                    }
                    &Right {
                        animation: Carousel-rotating-right-5 250ms forwards ease-in;
                        &.is-wraparound {
                            animation: Carousel-rotating-right-5, Carousel-rotating-right-0;
                            animation-duration: 250ms, 250ms;
                            animation-fill-mode: forwards, forwards;
                            animation-timing-function: ease-in, ease-out;
                            animation-delay: 0ms, 250ms;
                        }
                    }
                }
            }
            &.is-beforePrev {
                display: block;
                left: 0;
                opacity: 0;
                transform: translate(-50%, -50%) scale(0.3);
                &.is-animating {
                    animation: Carousel-rotating-right-0 250ms both ease-out;
                    animation-delay: 250ms;
                }
            }
            &.is-afterNext {
                display: block;
                left: 100%;
                opacity: 0;
                transform: translate(-50%, -50%) scale(0.3);
                &.is-animating {
                    animation: Carousel-rotating-left-5 250ms both ease-out;
                    animation-delay: 250ms;
                }
            }
        }
    }

    &--scrolling, &--scrolling-single{
        .Carousel-wrapper {
            overflow-x: hidden;
        }
        .Carousel-content {
            display: inline-flex;
            flex-flow: row nowrap;
            align-items: stretch;
            gap: 0;
        }
        .Carousel-prev {
            left: 0;
        }
        .Carousel-next {
            right: 0;
        }

        .CarouselSlide {
            width: 100vw;
            &.is-wrapping {
                position: absolute;
                top: 0;
                bottom: 0;
            }
        }

        &.is-wrappingLeft {
            .Carousel-content {
                padding-left: 100vw;
            }
        }
        &.is-wrappingRight {
            .Carousel-content {
                padding-left: 100vw;
                padding-right: 100vw;
            }
        }

        &:not(.is-animating) {
            .Carousel-wrapper {
                scroll-snap-type: x mandatory;
            }
            .CarouselSlide {
                scroll-snap-align: start;
            }
        }
    }

    &--single, &--scrolling-single {
        .Carousel-controls {
            display: none;
        }
    }
}
