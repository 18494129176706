// Horizontal aspect ratios
.aspect-wide-bar {
    aspect-ratio: 43/13;
}
.aspect-hd {
    aspect-ratio: 16/9;
}
.aspect-uhd {
    aspect-ratio: 21/10;
}
.aspect-photo {
    aspect-ratio: 3/2;
}

// Vertical aspect ratios
.aspect-square {
    aspect-ratio: 1/1;
}
.aspect-photo-portrait {
    aspect-ratio: 2/3;
}

// Spacers
.space-minimum {
    height: $SPACE_MINIMUM;
}
.space-thin {
    height: $SPACE_THIN;
}
.space-smaller {
    height: $SPACE_SMALLER;
}
.space-small {
    height: $SPACE_SMALL;
}
.space-medium {
    height: $SPACE_MEDIUM;
}
.space-large {
    height: $SPACE_LARGE;
}
.space-larger {
    height: $SPACE_LARGER;
}
.space-huge {
    height: $SPACE_HUGE;
}

// Layout helpers
.padding-minimum { padding: $SPACE_MINIMUM; }
.padding-thin { padding: $SPACE_THIN; }
.padding-smaller { padding: $SPACE_SMALLER; }
.padding-small { padding: $SPACE_SMALL; }
.padding-medium { padding: $SPACE_MEDIUM; }
.padding-large { padding: $SPACE_LARGE; }
.padding-larger { padding: $SPACE_LARGER; }
.padding-huge { padding: $SPACE_HUGE; }

.padding-horizontal-minimum { padding: 0 $SPACE_MINIMUM; }
.padding-horizontal-thin { padding: 0 $SPACE_THIN; }
.padding-horizontal-smaller { padding: 0 $SPACE_SMALLER; }
.padding-horizontal-small { padding: 0 $SPACE_SMALL; }
.padding-horizontal-medium { padding: 0 $SPACE_MEDIUM; }
.padding-horizontal-large { padding: 0 $SPACE_LARGE; }
.padding-horizontal-larger { padding: 0 $SPACE_LARGER; }
.padding-horizontal-huge { padding: 0 $SPACE_HUGE; }

.padding-vertical-minimum { padding: $SPACE_MINIMUM 0; }
.padding-vertical-thin { padding: $SPACE_THIN 0; }
.padding-vertical-smaller { padding: $SPACE_SMALLER 0; }
.padding-vertical-small { padding: $SPACE_SMALL 0; }
.padding-vertical-medium { padding: $SPACE_MEDIUM 0; }
.padding-vertical-large { padding: $SPACE_LARGE 0; }
.padding-vertical-larger { padding: $SPACE_LARGER 0; }
.padding-vertical-huge { padding: $SPACE_HUGE 0; }

.margin-minimum { margin: $SPACE_MINIMUM; }
.margin-thin { margin: $SPACE_THIN; }
.margin-smaller { margin: $SPACE_SMALLER; }
.margin-small { margin: $SPACE_SMALL; }
.margin-medium { margin: $SPACE_MEDIUM; }
.margin-large { margin: $SPACE_LARGE; }
.margin-larger { margin: $SPACE_LARGER; }
.margin-huge { margin: $SPACE_HUGE; }

.margin-horizontal-minimum { margin: 0 $SPACE_MINIMUM; }
.margin-horizontal-thin { margin: 0 $SPACE_THIN; }
.margin-horizontal-smaller { margin: 0 $SPACE_SMALLER; }
.margin-horizontal-small { margin: 0 $SPACE_SMALL; }
.margin-horizontal-medium { margin: 0 $SPACE_MEDIUM; }
.margin-horizontal-large { margin: 0 $SPACE_LARGE; }
.margin-horizontal-larger { margin: 0 $SPACE_LARGER; }
.margin-horizontal-huge { margin: 0 $SPACE_HUGE; }

.margin-vertical-minimum { margin: $SPACE_MINIMUM 0; }
.margin-vertical-thin { margin: $SPACE_THIN 0; }
.margin-vertical-smaller { margin: $SPACE_SMALLER 0; }
.margin-vertical-small { margin: $SPACE_SMALL 0; }
.margin-vertical-medium { margin: $SPACE_MEDIUM 0; }
.margin-vertical-large { margin: $SPACE_LARGE 0; }
.margin-vertical-larger { margin: $SPACE_LARGER 0; }
.margin-vertical-huge { margin: $SPACE_HUGE 0; }

.align-left {
    text-align: left;
}
.align-center {
    text-align: center;
}
.align-right {
    text-align: right;
}

.inverted {
    filter: invert(1);
}
.inverted-text {
    @include INVERT_COLORS;
}
.is-hidden {
    display: none !important;
}
.is-visible {
    display: block !important;
}

.italic, .italics {
    font-style: italic;
}

.scroll-disabled {
    overflow-y: hidden;
}

.inset-content {
    @include INSET_CONTENT;
}

.analytics-highlight {
    outline: solid 2px #FF0000 !important;
}

.marginBottomAdd {
    margin-bottom: 24px;
}
