.PlatformOption {
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: stretch;
    background-color: $COLOR_INVERTED_BACKGROUND;
    --platform-color: #{$COLOR_PLATFORM_PC};
    --platform-color-text: #{$COLOR_INVERTED_TEXT};

    cursor: pointer;

    &--psn {
        --platform-color: #{$COLOR_PLATFORM_PLAYSTATION};
    }
    &--xbox {
        --platform-color: #{$COLOR_PLATFORM_XBOX};
    }
    &--nsw {
        --platform-color: #{$COLOR_PLATFORM_NINTENDO};
    }
    &--ios {
        --platform-color: #{$COLOR_PLATFORM_IOS};
    }
    &--android {
        --platform-color: #{$COLOR_PLATFORM_ANDROID};
    }
    &--steam {
        --platform-color: #{$COLOR_PLATFORM_STEAM};

        .PlatformOption-icon {
            background-color: #144679 !important;
        }
    }

    &-icon {
        background-color: var(--platform-color);
        padding: $SPACE_SMALL;
    }
    &-iconImage {
        display: inline-block;
        width: 60px;
        height: 48px;
        object-fit: contain;
        vertical-align: middle;
    }
    &-label {
        position: relative;
        display: grid;
        align-items: center;
    }
    &-labelOverlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: var(--platform-color);
        opacity: 0.6;

        overflow: hidden;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 120%;
            background-color: rgba(0,0,0,.5);
            transform: skew(-10deg) translateX(-110%);
            transition: transform $TRANSITION_TRANSLATE_BUTTON;
        }
    }
    &-text {
        position: relative;
        padding: 0 $SPACE_SMALL;
        text-align: left;
        z-index: 1;

        @include TYPOGRAPHY_PARAGRAPH_LARGE;
        text-decoration: none;
        color: var(--platform-color-text);

        span{
            background-color: #DCC277;
            text-transform: uppercase;
            text-decoration: none;
            border-radius: 5px;
            padding:5px;
            font-size: 10px;
            position: relative;
            top: -3px;
            color:#000000;
            font-weight: bold;
            left: 5px;
        }
    }

    &:hover {
        .PlatformOption-labelOverlay {
            &:after {
                transform: skew(-10deg) translateX(10%);
            }
        }
    }
}
