// Signup and signin modal content
@import "../style-guide/_variables";
@import "../style-guide/_typography";
@import "../style-guide/_responsive";

.AuthModal {
    position: fixed;
    display: none;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 500;

    pointer-events: none;

    &.is-active {
        display: block;
        pointer-events: all;
    }

    &-background {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.9);
    }

    &-closerRow {
        position: relative;
        margin: $SPACE_LARGE auto 0;
        width: 80%;
        max-width: 1440px;
        text-align: right;

        @include breakpoint-mobile-only {
            margin: 0;
            width: 100%;
            text-align: left;
        }
    }
    &-closer {
        position: relative;
        text-align: left;
        cursor: pointer;
        margin-right: -24px;
        z-index: 10;

        display: inline-grid;
        align-items: center;
        grid-template-columns: 1fr auto;
        gap: 0;
        padding: $SPACE_MEDIUM;

        .Icon {
            width: 24px;
            height: 24px;
            vertical-align: middle;
            filter: invert(1);
        }
        span {
            color: $COLOR_INVERTED_TEXT;
        }
    }

    &-logo {
        margin-top: $SPACE_LARGE;
        text-align: center;
    }
    &-logoImage {
        display: inline-block;
        width: 80%;
        max-width: 462px;
    }

    &-content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow-y: auto;
    }

    a {
        color: $COLOR_INVERTED_TEXT;

        &:hover {
            color: $COLOR_RED;
        }
    }
}

.AuthModalPage {
    display: none;
    position: relative;
    margin: 0 auto;
    width: 80%;
    max-width: 1440px;

    &.is-active {
        display: block;
    }

    &--signup {

    }

    &--signin {

    }
}

.AuthModalLogo {
    text-align: center;

    &-image {
        display: inline-block;
        width: 80%;
        max-width: 462px;
        padding-top: 30px;
    }
}


.AuthModalTitle {
    position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: minmax($SPACE_LARGE, 1fr) auto minmax($SPACE_LARGE, 1fr);
    gap: $SPACE_LARGE;
    margin: $CONTENT_GUTTER_DESKTOP 0;

    @include breakpoint-mobile-only {
        display: flex;
        justify-content: center;
    }

    &-lines {
        height: $SPACE_SMALLER;
        border-top: solid 1px $COLOR_INVERTED_TEXT;
        border-bottom: solid 1px $COLOR_INVERTED_TEXT;

        @include breakpoint-mobile-only {
            display: none;
        }
    }
    &-content {
        text-align: center;

        @include TYPOGRAPHY_H6_ALTERNATE;

        @include breakpoint-mobile-only {
            text-transform: none;
        }
    }
}

.AuthPlatforms {
    display: grid;
    grid-template-columns: 1fr;
    gap: $SPACE_SMALL;
    padding: 20px 0px;
    @include breakpoint-tablet-up {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    &--triple {
        @include breakpoint-desktop-up {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }
}

.AuthPageContainer {
    width: 80%;
    max-width: 640px;
    margin: 0 auto;
}

.AuthRememberRow {
    display: flex;
    flex-flow: row wrap;
    gap: $SPACE_SMALLER $SPACE_MEDIUM;
    margin-bottom: $SPACE_MEDIUM;

    &-remember,
    &-reset {
        font-size: $FONT_SIZE_BASE;
    }
    &-remember {
        text-align: left;
        display: flex;
        flex-flow: row nowrap;
        gap: $SPACE_SMALLER;
        align-items: center;

        input {
            display: inline-block;
            margin: 0 $SPACE_SMALLER 0 0 !important;
            // Ugh
            top: 0 !important;
            float: none !important;
        }
    }
    &-reset {
        text-align: right;
        flex: 1 1 auto;
    }

    @include mq(mobile){
        flex-direction:column;
        .AuthRememberRow-reset{
            text-align: center;
            padding-top:20px;
        }
        
    }
}

#submit-btn{
    text-align: center;
}

@media (max-width: 1050px) {
    form [data-tip]{

        // Remove tip box itself as well as the pointer triangle 
        &::before,
        &::after{
            display: none !important; 
        }
    }
}
