// FIXME: This should be moved out of the main bundle eventually,
// FIXME: since it's just for the style guide itself.

#style-guide {
    code {
        color: $COLOR_DARK_RED;
    }
    .StyleGuideColorSwatches {
        display: flex;
        flex-flow: row wrap;
        gap: $SPACE_MEDIUM;
    }
    .StyleGuideColorSwatch {
        position: relative;
        display: inline-block;
        border: solid $SPACE_THIN rgba(0,0,0,.3);
        width: $SPACE_BASE * 20;
        aspect-ratio: 4/3;

        &:before {
            position: absolute;
            left: $SPACE_THIN;
            right: $SPACE_THIN;
            bottom: $SPACE_THIN;
            padding-bottom: $SPACE_SMALL;
            background-color: rgba(255,255,255,.5);
            text-align: center;
        }
        &:after {
            position: absolute;
            left: $SPACE_THIN;
            right: $SPACE_THIN;
            bottom: $SPACE_THIN;
            font-size: 0.8rem;
            font-weight: 700;
            line-height: 1.0;
            text-align: center;
        }
    }
    .StyleGuideColorBlock {
        display: inline-flex;
        flex-flow: row nowrap;
        width: $SPACE_BASE * 50;
        align-items: center;

        &-list {
            font-size: 0.8rem;
        }

        @mixin COLOR_SWATCH($index, $color, $label) {
            &:nth-child(#{$index}) .StyleGuideColorSwatch {
                background-color: $color;
                &:before { content: "#{$label}" } &:after { content: "#{$color}" }
            }
        }

        @include COLOR_SWATCH(1, $COLOR_WHITE, "Background");
        @include COLOR_SWATCH(2, $COLOR_RED, "Accent");
        @include COLOR_SWATCH(3, $COLOR_DARK_RED, "Accent Hover");
        @include COLOR_SWATCH(4, $COLOR_VERY_DARK_GRAY, "Secondary Hover");
        @include COLOR_SWATCH(5, $COLOR_MEDIUM_GRAY, "Disabled");
        @include COLOR_SWATCH(6, $COLOR_LIGHT_GRAY, "Divider");
        @include COLOR_SWATCH(7, $COLOR_DARK_GRAY, "Subtitle");
        @include COLOR_SWATCH(8, $COLOR_PLATFORM_PC, "PC");
        @include COLOR_SWATCH(9, $COLOR_PLATFORM_PLAYSTATION, "Playstation");
        @include COLOR_SWATCH(10, $COLOR_PLATFORM_XBOX, "Xbox");
        @include COLOR_SWATCH(11, $COLOR_PLATFORM_NINTENDO, "Nintendo");
        @include COLOR_SWATCH(12, $COLOR_BLACK, "Body Text");
        @include COLOR_SWATCH(13, $COLOR_LIGHT_RED, "Error Inverted");
        @include COLOR_SWATCH(14, $COLOR_MODAL_OVERLAY, "Modal Overlay");

    }

    .StyleGuideIcons {
        display: flex;
        flex-flow: row wrap;
        gap: $SPACE_MEDIUM;
        margin: $SPACE_LARGE 0;
    }

    .StyleGuideIcon {
        display: inline-flex;
        flex-flow: column nowrap;
        white-space: nowrap;
        align-items: center;
        text-align: center;
        width: 100px;
    }

    .StyleGuideCodeBlock {
        position: relative;
        overflow-x: auto;
        background-color: #eeeee0;
        padding: $SPACE_MEDIUM;
        border-radius: $SPACE_SMALLER;
        box-shadow: inset 1px 3px 6px rgba(0,0,0,.1);
        max-width: 50rem;

        pre {
            font-size: 0.8rem;
            margin: 0;
        }

        &.is-closed {
            &:before {
                content: "Click to show code block";
                position: absolute;
                left: $SPACE_MEDIUM;
                top: 50%;
                transform: translateY(-50%);
                font-size: 0.8rem;
                opacity: 50%;
            }

            pre {
                display: none;
            }
        }
    }
    .StyleGuideFormBlock {
        display: flex;
        flex-flow: row wrap;
        gap: $SPACE_MEDIUM;
        margin-bottom: $SPACE_LARGE;
    }

    #duviri {
    }
}
