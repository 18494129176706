@mixin HEADER_DROPDOWN_BACKGROUND {
    &:before {
        content: "";
        position: absolute;
        top: 8px;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: var(--color-navigation-background-dropdown);
        box-shadow: $SHADOW_NAVIGATION_DROPDOWN;
        border-radius: $SPACE_SMALLER;
    }
}

.HeaderNavigationContainer {
    position: relative;
    height: 0; // $HEADER_DESKTOP_HEIGHT;
    box-sizing: border-box;
    z-index: $Z_INDEX_NAV;

    &.is-collapsed {
        //height: $HEADER_TABLET_HEIGHT;
        &.is-mobile {
            //height: $HEADER_MOBILE_HEIGHT;
        }
    }
}
.HeaderNavigationBar {
    --color-navigation-text: #{$COLOR_NAVIGATION_TEXT};
    --color-navigation-text-hover: #{$COLOR_NAVIGATION_TEXT};
    --color-navigation-text-dropdown: #{$COLOR_NAVIGATION_DROPDOWN_LINK};
    --color-navigation-background: #{$COLOR_NAVIGATION_BACKGROUND};
    --color-navigation-background-dropdown: #{$COLOR_NAVIGATION_BACKGROUND_SECONDARY};
    --color-navigation-background-hover: #{$COLOR_NAVIGATION_BACKGROUND_HOVER};
    --color-navigation-border: #{$COLOR_NAVIGATION_BACKGROUND};

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    color: var(--color-navigation-text);
    z-index: $Z_INDEX_NAV;
    max-width: 100%;

    &:before {
        content: '';
        @include FULL_COVER;
        background-color: var(--color-navigation-background);
        backdrop-filter: $BACKDROP_FILTER_NAVIGATION;
    }

    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        height: $SPACE_MINIMUM;
        background: var(--color-navigation-border);
        pointer-events: none;
    }

    &-grid {
        position: relative;
        height: $HEADER_DESKTOP_HEIGHT;

        display: grid;
        grid-template-columns: auto 1fr auto;
        align-items: center;
        gap: $SPACE_MEDIUM;
        padding: 0 $SPACE_MEDIUM;

        @include breakpoint-mobile-narrow {
            gap: 0 $SPACE_SMALL;
            padding: 0 $SPACE_SMALL;
        }
    }

    &-logoContainer {
        height: 100%;
    }
    &-logoLink {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        height: 100%;
        padding: 0 $SPACE_MEDIUM;

        @include breakpoint-mobile-narrow {
            padding: 0;
        }
    }
    &-logo {
        height: 29px;
    }
    &-mobileLogo {
        display: none;
        height: 42px;
    }

    &-desktopMenuContainer {
        position: relative;
        text-align: right;
        height: 100%;
        overflow-x: hidden;
    }

    &-controlsContainer {
        text-align: right;
        height: 100%;
        min-width: 232px;
    }

    &-mobileMenuToggle {
        display: none;
        text-align: right;

        &Inner {
            display: inline-block;
            padding: 12px;
            cursor: pointer;
            height: 44px;
            width: 44px;
        }
    }
    &-mobileMenuIcon {

    }
    &-mobileMenuOverlay {
        display: none;
        position: fixed;
        background-color: $COLOR_MOBILE_OVERLAY;
        top: $HEADER_TABLET_HEIGHT;
        right: 0;
        left: 0;
        bottom: 0;
    }
    &-mobileMenuContainer {
        display: none;
        position: fixed;
        top: $HEADER_TABLET_HEIGHT;
        right: 0;
        bottom: 0;
        min-width: 380px;
        overflow-y: auto;
        z-index: $Z_INDEX_NAV;

        background-color: var(--color-navigation-background);
        backdrop-filter: $BACKDROP_FILTER_NAVIGATION;
    }
    &-mobileButtonContainer {
        display: none;
    }
    &.is-desktop {
        .HeaderNavigationBar-desktopMenuContainer {
            overflow-x: unset;
        }
    }
    &.is-collapsed {
        .HeaderNavigationBar-grid {
            height: $HEADER_TABLET_HEIGHT;
            grid-template-columns: auto 1fr auto;
        }
        .HeaderNavigationBar-controlsContainer {
            overflow-x: hidden;
        }
        .HeaderNavigationBar-desktopMenuContainer {
            display: none;
        }
        .HeaderNavigationBar-mobileMenuToggle {
            display: block;
        }
        .HeaderUserMenu {
            position: fixed;
            top: $HEADER_TABLET_HEIGHT;
            bottom: 0;
            right: 0;
            min-width: 360px;

            &-content {
                padding: 0;
            }
            &-row,
            &-link {
                padding: $SPACE_MEDIUM $SPACE_LARGE;
            }

            &-crossSave {
                display: none;
                &.is-enabled {
                    display: block;
                }
            }
        }

        &.is-mobile {
            .HeaderNavigationBar-grid {
                grid-template-columns: auto 1fr;
                height: $HEADER_MOBILE_HEIGHT;
            }
            .HeaderNavigationBar-mobileLogo {
                height: 21px;
            }

            .HeaderNavigationBar-controlsContainer {
                display: none;
            }
            .HeaderNavigationBar-mobileMenuOverlay {
                top: $HEADER_MOBILE_HEIGHT;
            }
            .HeaderNavigationBar-mobileMenuContainer {
                left: 0;
                top: $HEADER_MOBILE_HEIGHT;
                min-width: auto;
            }
            .HeaderNavigationBar-mobileButtonContainer {
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                gap: $SPACE_MEDIUM;
                margin-top: $SPACE_MEDIUM;
            }
            .HeaderUserMenu {
                position: fixed;
                top: $HEADER_MOBILE_HEIGHT;
                left: 0;
                bottom: 0;
                overflow-y: auto;
                min-width: auto;
            }
        }

        &.is-open {
            .HeaderNavigationBar-mobileMenuOverlay {
                display: block;
            }
            .HeaderNavigationBar-mobileMenuContainer {
                display: block;
            }
        }
        &.is-user-open {
            .HeaderNavigationBar-mobileMenuOverlay {
                display: block;
            }
        }
    }
    &.is-user-open {
        .HeaderUserMenu {
            display: grid;
        }
    }

    &--dark {
        --color-navigation-text: #{$COLOR_NAVIGATION_DARKMODE_TEXT};
        --color-navigation-text-hover: #{$COLOR_NAVIGATION_DARKMODE_TEXT};
        --color-navigation-text-dropdown: #{$COLOR_NAVIGATION_DARKMODE_DROPDOWN_LINK};
        --color-navigation-background: #{$COLOR_NAVIGATION_DARKMODE_BACKGROUND};
        --color-navigation-background-hover: #{$COLOR_NAVIGATION_DARKMODE_BACKGROUND_HOVER};
    }
}
.HeaderMenuItem {
    &-live {
        display: inline-block;
        @include BADGE_STYLES;
        background-color: $COLOR_GOLD;
        color: $COLOR_TEXT;
    }
}
.HeaderDesktopMenu {
    position: relative;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: $SPACE_SMALL;

    height: 100%;
    text-align: left;
    white-space: nowrap;

    .HeaderMenuItem {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        white-space: nowrap;

        &-label {
            position: relative;
            display: inline-flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: $SPACE_SMALL;
            padding: $SPACE_SMALL;
            border-radius: $SPACE_SMALLER;

            color: var(--color-navigation-text);
            font-size: $FONT_SIZE_NAVIGATION;
            font-weight: $FONT_WEIGHT_NAVIGATION;
            text-transform: $TEXT_TRANSFORM_NAVIGATION;
            text-decoration: none;
            cursor: pointer;

            &:hover,
            &:active {
                text-decoration: none;
            }
        }

        &-icon {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            pointer-events: none;
        }

        &-live {
            margin-left: $SPACE_SMALL;
            margin-top: -$SPACE_SMALL;
            margin-bottom: -$SPACE_SMALL;
        }

        &-dropdown {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            list-style: none;
            padding-top: 26px;
            padding-bottom: 18px;
            line-height: 1.2;

            cursor: pointer;
            z-index: $Z_INDEX_NAV;

            @include HEADER_DROPDOWN_BACKGROUND;
        }

        &-subheading {
            position: relative;
            color: $COLOR_NAVIGATION_DROPDOWN_SUBHEADING;
            font-size: $FONT_SIZE_MIN;
            font-weight: 700;
            text-transform: uppercase;
            padding: 12px $CONTENT_GUTTER_DESKTOP;
            &:first-child {
                padding-top: 6px;
            }
        }
        &-option {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: $SPACE_SMALLER;

            color: var(--color-navigation-text-dropdown);
            text-decoration: underline transparent;
            font-size: $FONT_SIZE_BASE;
            font-weight: 400;
            padding: 6px $CONTENT_GUTTER_DESKTOP;
            transition: $TRANSITION_FAST;

            &:hover {
                text-decoration-color: var(--color-navigation-text-dropdown);
            }
        }
        &-optionLink {
            color: var(--color-navigation-text-dropdown);
            padding: $SPACE_SMALL;
            filter: brightness(0) saturate(100%);
        }

        &--dropdown,
        &--external {
            .HeaderMenuItem-label {
                padding-right: $SPACE_MEDIUM + $SPACE_SMALL;
            }
        }

        &.is-highlighted {
            .HeaderMenuItem-label {
                color: #5143A8;
                &:hover {
                    color: #5143A8;
                }
            }
        }

        &.is-current,
        &.is-open,
        &:hover {
            .HeaderMenuItem-label {
                background-color: var(--color-navigation-background-hover);
                color: var(--color-navigation-text-hover);
            }
        }

        &.is-open {
            .HeaderMenuItem-dropdown {
                display: block;
            }
        }

        &:not(.is-closed):hover {
            .HeaderMenuItem-dropdown {
                display: block;
            }
        }

    }

    &-bar {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: $SPACE_SMALLER;
        background-color: $COLOR_ACCENT;
        pointer-events: none;
    }

}
.HeaderNewLabel {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 8px;

    &-tag {
        display: inline-block;
        @include BADGE_STYLES;
        background-color: $COLOR_GOLD;
        color: $COLOR_TEXT;
    }
}

.HeaderMobileMenu {
    position: relative;

    .HeaderMenuItem {
        position: relative;
        display: block;
        height: 100%;
        text-align: left;
        white-space: nowrap;

        &-label {
            display: grid;
            grid-template-columns: 1fr auto;
            align-items: center;
            gap: $SPACE_MEDIUM;
            padding: $SPACE_MEDIUM $SPACE_MEDIUM $SPACE_MEDIUM $SPACE_LARGE;

            color: var(--color-navigation-text);
            font-weight: bold;
            cursor: pointer;

            &.is-inline {
                display: flex;
                gap: 0;
            }
        }

        &-icon {
            padding: 12px;
            width: 44px;
            height: 44px;
            margin: -12px 0;
        }

        &-live {
            margin-left: $SPACE_MEDIUM;
        }

        &-dropdown {
            display: none;
            background-color: $COLOR_NAVIGATION_BACKGROUND_SECONDARY;
            list-style: none;
            cursor: pointer;

            &:hover {
                color: $COLOR_NAVIGATION_LINK;
                .HeaderMenuItem-linkIcon {
                    filter: var(--filter-navigation-icon-dropdown, invert(36%) sepia(0%) saturate(0%) hue-rotate(327deg) brightness(91%) contrast(87%));
                }
            }
        }

        &-option {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: $SPACE_SMALLER;

            font-size: 16px;
            font-weight: 700;
            padding: $SPACE_MEDIUM $SPACE_LARGE $SPACE_MEDIUM $SPACE_LARGER;
            --color-link: inherit;
            --color-link-hover: #{$COLOR_NAVIGATION_LINK_HOVER};

            &:hover {
                background-color: $COLOR_NAVIGATION_LINK_BACKGROUND_HOVER;
                .HeaderMenuItem-linkIcon {
                    filter: brightness(0) saturate(100%);
                }
            }

        }

        &.is-highlighted {
            .HeaderMenuItem-label {
                color: #5143A8;
                &:hover {
                    color: #5143A8;
                }
            }
        }

        &.is-open {
            .HeaderMenuItem-dropdown {
                display: block;
            }
            .HeaderMenuItem-icon {
                transform: rotate(180deg);
            }
        }

        .HeaderMenuItem-label .event-label {
            display: flex;
            flex-direction: row;
        }

        .HeaderMenuItem-label .event-label .new-label, .HeaderMenuItem-option .new-label{
            margin-right: 10px;
            padding: 2px 5px;
            background-color: #B30000 !important;
            border-radius: 2px;
            color: white;
            text-transform: uppercase;
        }
        .HeaderMenuItem-option .new-label {
            margin-right: 4px;
        }
    }
}

.HeaderNavigationControls {
    display: inline-grid;
    grid-template-columns: auto auto;
    align-items: center;
    gap: $SPACE_MEDIUM;
    white-space: nowrap;
    height: 100%;
}

.HeaderNavigationLogged {
    &In {
        display: none;
    }
    &Out {
        display: none;
    }
}

body {
    &.logged-in {
        .HeaderNavigationLoggedIn {
            display: inline;
        }
        .is-collapsed {
            .HeaderNavigationBar-logo {
                display: none;
            }

            .HeaderNavigationBar-mobileLogo {
                display: block;
            }
        }
    }
    &.loaded-in {
        .HeaderUserProfile {
            filter: opacity(1);
        }
    }
    &.logged-out {
        .HeaderNavigationLoggedOut {
            display: inline;
        }
    }
}

.HeaderUserProfile {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    text-align: left;
    filter: opacity(0);
    transition: filter 250ms;

    &-container {
        position: relative;
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 0 $SPACE_SMALL;
        cursor: pointer;
    }
    &-avatar {
        position: relative;
        height: 42px;
        width: 42px;
        grid-row: 1 / span 2;
        background: $COLOR_NAVIGATION_BACKGROUND_AVATAR;
    }
    &-name {
        position: relative;
        font-weight: 700;
        line-height: 1;
        padding-right: $SPACE_LARGE;

        &Text {
            display: inline-block;
            width: 150px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            @include breakpoint-mobile-only {
                width: 125px;
            }
        }
    }
    &-information {
        line-height: 1;
    }
    &-legendary {
        display: none;
    }
    &-platinum {
        display: inline;
    }
    &-platinumDivider {
        position: relative;
        padding-left: $SPACE_MEDIUM;
        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: $SPACE_SMALL;
            width: 1px;
            height: 0.9rem;
            background-color: $COLOR_TEXT;
            transform: translateY(-50%);
        }
    }
    &-platinumIcon {
        height: 14px;
        width: 14px;
        position: relative;
        bottom: -1px;
    }
    &-dropdownIcon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    &.is-notification-active {
        .HeaderUserProfile-container {
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 13px;
                height: 13px;
                border-radius: 50%;
                background-color: $COLOR_NAVIGATION_NOTIFICATION;
                transform: translate(-50%, -50%);
            }
        }
    }
    &.is-legendary {
        .HeaderUserProfile-mastery {
            display: none;
        }
        .HeaderUserProfile-legendary {
            display: unset;
        }
    }
}

.HeaderUserMenu {
    --color-link: var(--color-navigation-text-dropdown);
    --color-link-hover: var(--color-navigation-text-dropdown);

    display: none;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr;
    position: absolute;
    right: $SPACE_MEDIUM;
    top: 100%;
    min-width: 232px;

    @include HEADER_DROPDOWN_BACKGROUND;

    &-content {
        position: relative;
        padding: $SPACE_SMALL 0;
    }

    &-row {
        position: relative;
        padding: $SPACE_SMALL $SPACE_MEDIUM;

        &.is-hidden {
            display: none;
        }
        &.is-active {
            display: block;
        }
        &.is-highlighted {
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: $SPACE_SMALL;
                bottom: $SPACE_SMALL;
                width: $SPACE_SMALLER;
                background-color: $COLOR_NAVIGATION_NOTIFICATION;
            }
        }
        &.is-unlinked {
            .HeaderUserMenu-unlinked {
                display: unset;
            }
        }
        &.is-linked {
            .HeaderUserMenu-linked {
                display: unset;
            }
        }
        &.is-claimable {
            .HeaderUserMenu-rewards {
                display: unset;
            }
        }
        &.is-redeemable {
            .HeaderUserMenu-redeem {
                display: unset;
            }
        }
    }
    &-rowTitle {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        font-weight: 700;
    }
    &-linked,
    &-unlinked,
    &-rewards,
    &-redeem,
    &-coupon,
    &-couponDiscount,
    &-couponMultiplier {
        display: none;
    }
    &-crossSaveIcon {
        margin-right: $SPACE_SMALL;
        filter: $FILTER_CROSS_SAVE;
    }
    &-twitchIcon {
        margin-right: $SPACE_SMALL;
        filter: $FILTER_PLATFORM_TWITCH;
    }
    &-primeIcon {
        margin-right: $SPACE_SMALL;
        filter: $FILTER_PLATFORM_TWITCH;
    }
    &-claimLink {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }
    &-claimIcon {
        margin-left: $SPACE_SMALLER;
    }
    &-coupon, &-couponDiscount, &-couponMultiplier  {
        display: none;

        &.is-active {
            display: block;
        }
    }
    &-redeemText {
        white-space: normal;
        max-width: 250px;
    }
    &-platinum {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        color: $COLOR_DARK_GRAY;

        &Icon {
            margin-right: $SPACE_SMALLER;
        }
        &Divider {
            margin: 0 $SPACE_SMALLER;
        }
        &Link {
            text-decoration: underline;
        }
    }

    &-divider {
        position: relative;
        background-color: $COLOR_DIVIDER;
        height: 1px;
    }
    &-links {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto 1fr;
        white-space: nowrap;
        padding: $SPACE_SMALL 0;
    }
    &-link {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: $SPACE_SMALL $SPACE_MEDIUM;
        cursor: pointer;

        &> .Icon {
            margin-left: $SPACE_SMALL;
        }

        &Last {
            align-self: end;
        }
    }
}

.HeaderTakeover {
    position: relative;
    display: none;
    height: 0;
    overflow: hidden;
    background-color: $COLOR_TAKEOVER_BACKGROUND;
    transition: height $SPEED_TAKEOVER_TRANSITION ease-in-out;
    background-image: url("../images/promo/whispers/takeover/runes-on-walls.jpg");
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;

    &-wrapper {
        position: relative;
        max-width: 1064px;
        margin: 0 auto;
        overflow-x: auto;
    }
    &-grid {
        position: relative;
        display: grid;
        grid-template-columns: repeat(3, minmax($WIDTH_TAKEOVER_CARD, 1fr));
        gap: $SPACE_MEDIUM;
        padding: $PADDING_TAKEOVER;
    }
    &-link {
        color: $COLOR_TAKEOVER_TEXT;
    }
    &-closer {
        position: absolute;
        top: 0;
        right: $SPACE_SMALL;
        background-color: $COLOR_TAKEOVER_CLOSER;
        padding: $SPACE_SMALL;
        border-radius: 0 0 $SPACE_SMALLER $SPACE_SMALLER;
        width: 40px;
        height: 40px;
        cursor: pointer;

        @include breakpoint-wide-up {
            right: auto;
            left: calc(50% + 744px);
        }
    }
    &-closerIcon {
        height: 24px;
        width: 24px;
        filter: invert(1);
    }

    &.is-primed {
        display: block;
    }
    &.is-active {
        height: $HEIGHT_TAKEOVER_OUTER;
    }
    .HeaderTakeoverCard {
        position: relative;
        border: solid 1px $COLOR_TAKEOVER_PANEL_BORDER;

        display: grid;
        grid-template-columns: auto 1fr;
        min-width: $WIDTH_TAKEOVER_CARD;

        &-image {
//            display: inline-block;
            vertical-align: bottom;
            height: $HEIGHT_TAKEOVER_INNER;
            aspect-ratio: 4/3;
            object-fit: cover;
        }
        &-content {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            color: $COLOR_TAKEOVER_TEXT;
            font-family: $FONT_FAMILY_BODY;
            font-size: $FONT_SIZE_SUB;
            font-weight: bold;
            padding: 0 $SPACE_MEDIUM;

            strong {
                color: $COLOR_TAKEOVER_ACCENT;
            }
        }
    }


    &.WitwPromo {
        .HeaderTakeover-wrapper {
            max-width: 1280px;
        }
        .HeaderTakeover-grid {
            padding: 0;
            grid-template-columns: auto auto minmax($WIDTH_TAKEOVER_CARD, 1fr);

            &.short {
                grid-template-columns: repeat(2, auto);
                @media (min-width: 1024px) {
                    display: none;
                }
                @media (max-width: 600px) {
                    grid-template-columns: repeat(2, 50%);
                }
            }
            &.long {
                @media (max-width: 1023px) {
                    display: none;
                }
            }
        }
        .HeaderTakeover-fullHeightImage {
            max-height: 174px;
            max-width: 100%;
            margin: 0;
            @media (max-width: 1024px) {
                margin: auto;
            }
        }
        .HeaderTakeoverDescriptionText {
            color: white;
            padding: 32px 8px;
            &-title {
                font-weight: bold;
            }
        }
        .HeaderTakeoverButton {
            margin-top: 8px;
            border: rgba(245, 230, 160, 1) 1px solid;
            background: black;
            color: rgba(245, 230, 160, 1);
        }
        .HeaderTakeoverCard {
            margin: 32px 0;
        }
    }
}

.FooterNavigationContainer {
    position: relative;
    background-color: $COLOR_FOOTER_BACKGROUND;
    z-index: $Z_INDEX_FOOTER;
    color: $COLOR_TEXT_FOOTER;

    &.is-inverted {
        color: $COLOR_NAVIGATION_DARKMODE_TEXT;
        background-color: $COLOR_NAVIGATION_DARKMODE_BACKGROUND_FOOTER;

        .FooterPlatformIcons,
        .FooterNavigationLanguageDropdown .Icon {
            filter: invert(1);
        }
        .FooterCopyrightText {
            color: $COLOR_NAVIGATION_DARKMODE_TEXT;
        }
        .FooterNavigationLinkDivider {
            background-color: $COLOR_INVERTED_TEXT;
        }
        .FooterNavigationLanguageDropdown-dropdown {
            background-color: $COLOR_NAVIGATION_DARKMODE_BACKGROUND_SECONDARY;
        }
        .FooterNavigationLanguageDropdown-option {
            &:hover {
                background-color: $COLOR_NAVIGATION_DARKMODE_BACKGROUND_SECONDARY;
            }
        }
        .FooterNavigationBar {
            color: $COLOR_NAVIGATION_DARKMODE_TEXT;
        }
        .FooterNavigationBar-inner {
            @include breakpoint-desktop-up {
                background-color: $COLOR_NAVIGATION_DARKMODE_BACKGROUND_SECONDARY;
            }
        }
        .FooterNavigationLink,
        .FooterRatingsContainer-content,
        .FooterNavigationLanguageDropdown-label,
        .FooterNavigationLanguageDropdown-optionLink {
            color: $COLOR_INVERTED_TEXT;
        }
    }
}
.FooterNavigationBar {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding: $SPACE_MEDIUM 0;
    color: $COLOR_NAVIGATION_TEXT;
    line-height: $LINE_HEIGHT_BASE;

    @include breakpoint-wide-up {
        width: $CONTENT_WIDTH_WIDE;
    }

    &-content {
        position: relative;
        @include RESPONSIVE_CONTENT_MARGINS;
    }

    &-inner {
        position: relative;
        @include RESPONSIVE_CONTENT_MARGINS;
        @include breakpoint-desktop-up {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            gap: $SPACE_MEDIUM;
            padding: $SPACE_MEDIUM;
            background-color: $COLOR_NAVIGATION_LINK_BAR;
        }
    }

    &-linkContainer {
        overflow-x: hidden;
        text-align: center;
    }
    &-links {
        @include breakpoint-tablet-down {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            text-align: left;
            gap: $SPACE_MEDIUM;
            margin: $SPACE_MEDIUM 0;
        }
        @include breakpoint-mobile-only{
            grid-template-columns: 1fr;
        }
        @include breakpoint-desktop-up {
            display: inline-flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;
            margin: $SPACE_MEDIUM 0;
        }
    }
    &-dropdownContainer {
        text-align: center;
    }
    &.is-expanded {
        .FooterNavigationBar-content {
            grid-template-columns: auto 1fr auto;
            align-items: center;
        }
        .FooterNavigationBar-homeLink {
            margin-right: $SPACE_LARGE;
        }
        .FooterNavigationBar-linkContainer {
            text-align: left;
        }
        .FooterNavigationBar-links {
            flex-flow: row nowrap;
            justify-content: flex-start;
            margin: 0;
            white-space: nowrap;
        }
        .FooterNavigationBar-dropdownContainer {
            text-align: right;
        }
    }
}

.FooterNavigationLink {
    margin: 0 $SPACE_MEDIUM;
    font-size: $FONT_SIZE_BASE;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    --color-link: #{$COLOR_TEXT_FOOTER};

    @include LINK_NO_UNDERLINE;

    #ot-sdk-btn.ot-sdk-show-settings {
        border: none;
        padding: 0;
        font: inherit;
        color: unset;
        background-color: unset;
        cursor: pointer;
        white-space: nowrap;
        word-break: normal;

        &:hover {
            color: var(--color-button-text-hover);
            background-color: unset;
        }
    }
}

.FooterNavigationLinkDivider {
    margin: -$SPACE_SMALLER $SPACE_MEDIUM;
    height: $SPACE_SMALL + $SPACE_MEDIUM;
    width: 1px;
    background-color: $COLOR_TEXT_FOOTER;

    @include breakpoint-tablet-down {
        display: none;
    }
}

.FooterNavigationLanguageDropdown {
    position: relative;
    display: inline-block;
    text-align: left;

    &-overlay {
        display: inline-grid;
        grid-template-columns: auto 1fr auto;
        align-items: center;
        padding: $SPACE_SMALL;
        gap: $SPACE_SMALL;
        background-color: transparent;
        border: solid 1px $COLOR_NAVIGATION_LANGUAGE_SELECTOR_BORDER;
        cursor: pointer;
    }

    &-languageIcon {

    }
    &-label {
        min-width: 100px;
        color: $COLOR_TEXT;
        text-transform: uppercase;
        font-weight: 700;
    }
    &-dropdownIcon {

    }
    &-dropdown {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: $COLOR_BACKGROUND;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
        list-style: none;
        cursor: pointer;
        padding: 0;
        z-index: $Z_INDEX_NAV;
    }
    &-option {
        font-size: 16px;
        font-weight: 700;
        color: $COLOR_TEXT_FOOTER;
        text-transform: uppercase;

        &:hover {
            background-color: $COLOR_LIGHT_GRAY;
        }
    }
    &-optionLink {
        display: block;
        --color-link: #{$COLOR_TEXT_FOOTER};
        padding: $SPACE_SMALLER $SPACE_MEDIUM;
    }

    &.is-open {
        .FooterNavigationLanguageDropdown-dropdown {
            display: block;
        }
    }
}

.FooterLowerContainer {
    line-height: $LINE_HEIGHT_BASE;

    &-inner {
        padding-bottom: $SPACE_HUGE;
    }
}

.FooterSocialLinks {
    padding: $SPACE_LARGE 0;
    text-align: center;

    @include breakpoint-wide-up {
        width: $CONTENT_WIDTH_WIDE;
        margin-left: auto;
        margin-right: auto;
    }

    &-title {
        font-size: 1.5em;
        font-weight: bold;
        line-height: 1;
        text-transform: uppercase;
    }
    &-divider {
        margin: $SPACE_MEDIUM 0;
        height: $SPACE_MINIMUM;
        background-image: linear-gradient(to right, transparent, $COLOR_MEDIUM_GRAY, transparent);
    }
}

.FooterSocialLink {
    display: inline-block;
    padding: 10px;
    height: 40px;
    width: 40px;
    margin: 0 $SPACE_SMALLER;
    background-color: $COLOR_FOOTER_SOCIAL_LINK;
    border-radius: 50%;

    &[data-platform="vkontakte"],
    &[data-platform="line"] {
        display: none;
    }
}

.lang-ru{
    .FooterSocialLink{
        &[data-platform="vkontakte"] {
            display: inline-block;
        }
        &[data-platform="facebook"] {
            display: none;
        }
    }
}
.lang-ja{
    .FooterSocialLink{
        &[data-platform="line"] {
            display: inline-block;
        }
    }
}

.FooterRatingsContainer {
    text-align: center;

    &-content {
        position: relative;
        display: inline-block;
        text-align: left;
        color: $COLOR_TEXT_FOOTER;
    }
}

.FooterRatingBoardCard {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: $SPACE_MEDIUM;
    align-items: center;

    &-image {
        height: 80px;
        aspect-ratio: 34/50;
        object-fit: contain;
    }
    &-divider {
        height: $SPACE_MINIMUM;
        background-color: $COLOR_MEDIUM_GRAY;
        margin: $SPACE_SMALL 0;
    }

    ul {
        list-style: disc;
        padding-inline-start: $SPACE_MEDIUM;
    }

    &--noText {
        grid-template-columns: auto;
    }
}

.FooterLogo {
    text-align: center;
    margin: $SPACE_LARGE 0;

    &-image {
        display: inline-block;
        width: 364px;
    }
}

.FooterCopyrightText {
    font-size: $FONT_SIZE_SUB;
    text-align: center;
    margin-bottom: $SPACE_LARGE;
}

.FooterPlatformIcons {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-items: center;
    justify-content: center;
    gap: $SPACE_SMALL $SPACE_MEDIUM;

    width: 90%;
    margin: 0 auto $SPACE_LARGE;

    &-steam { height: 20px; }
    &-epic { height: 32px; }
    &-switch { height: 34px; }
    &-psn { height: 36px; }
    &-xbox { height: 40px; }
    &-ios { height: 40px; }
    &-xboxone { height: 40px; filter: invert(1); }
}
