.AnalyticsDebug {
    display: none;
    position: fixed;
    right: 0;
    bottom: 0;
    height: 50vh;
    width: 100%;
    max-width: 860px;
    padding: $SPACE_LARGE;
    background-color: rgba($COLOR_BLACK, 0.8);
    color: $COLOR_WHITE;
    overflow-y: auto;
    z-index: 200;

    &.is-active {
        display: block;
    }

    &-title {
        text-align: center;
        margin-bottom: $SPACE_MEDIUM;
    }

    &-table {
        position: relative;
        font-size: $FONT_SIZE_SUB;
        overflow: hidden;
    }

    &-row {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: $SPACE_MEDIUM;
        cursor: pointer;

        &.is-direct {
            .AnalyticsDebug-index {
                color: $COLOR_MEDIUM_GRAY;
            }

            &:hover {
                color: $COLOR_GOLD;
            }
        }
        &.is-collapsed {
            max-height: 30px;
            overflow: hidden;

            .AnalyticsDebug-toggle {
                &:after {
                    content: '+';
                }
            }
        }

        &:nth-child(2n) {
            background-color: rgba($COLOR_WHITE, 0.1);
        }
        &:hover {
            background-color: rgba($COLOR_WHITE, 0.2);
            color: #66C866;
        }
    }
    &-toggle {
        position: relative;
        font-weight: bold;
        display: inline-block;
        text-align: center;
        width: 28px;
        height: 28px;
        border: solid 1px $COLOR_WHITE;
        opacity: 0.5;

        &:hover {
            opacity: 1;
        }

        &:after {
            content: '-';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    &-inner {
        display: grid;
        grid-template-columns: 1fr;
        gap: $SPACE_MEDIUM;

        @include breakpoint-tablet-up {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
    &-info {
        display: grid;
        grid-template-columns: auto 1fr auto;
        gap: $SPACE_SMALL;
    }
    &-index {
        font-weight: bold;
        color: #66C866;
    }
    &-element {
        font-weight: bold;
    }
    &-event {
    }
    &-source {
        grid-column: span 3;
        font-style: italic;
    }
    &-payload {
        font-family: 'Courier New', monospace;
        font-size: $FONT_SIZE_MIN;
        word-break: break-all;
    }
}
