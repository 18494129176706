.SubNavigationItem {
    position: relative;
    margin: 0;

    &-link {
        position: relative;
        display: inline-block;
        padding: $SPACE_MEDIUM;
        --color-link: transparent;
        --color-link-hover: transparent;
        font-weight: 700;

        &:after {
            content: attr(data-label);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            color: var(--color-subnavigation-link, #{$COLOR_INVERTED_TEXT});
            font-weight: 400;
        }

        &:hover {
            &:after {
                color: var(--color-subnavigation-link-hover, #{$COLOR_GOLD});
                font-weight: 700;
            }
        }
    }

    &--button {
        display: flex;
        align-items: center;
        margin: $SPACE_BASE;
    }

    &.is-active {
        .SubNavigationItem-link:after {
            font-weight: 700;
        }
    }
}
