.Sticky {
    position: relative;
    z-index: 10;

    .Sticky-content {
        position: relative;
    }

    &.is-fixed {
        .Sticky-content {
            position: fixed;
            left: 0;
            right: 0;
        }
    }
}
