.SectionLayout {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    gap: $ALTERNATING_CONTENT_GAP;
    align-items: center;

    @include breakpoint-tablet-up {
        grid-template-columns: minmax(0, 3fr) minmax(0, 2fr);
        gap: $ALTERNATING_CONTENT_GAP_TABLET;
    }
    @include breakpoint-desktop-up {
        gap: $ALTERNATING_CONTENT_GAP_DESKTOP;
    }

    &-mediaContainer {
        position: relative;
    }
    &-image {
        width: 100%;
    }
    &-media {
        position: relative;
    }
    &-content {
        position: relative;
    }

    &--right {
        @include breakpoint-tablet-up {
            grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
        }

        .SectionLayout {
            &-mediaContainer {
                @include breakpoint-tablet-up {
                    order: 2;
                }
            }
            &-content {
                @include breakpoint-tablet-up {
                    order: 1;
                    padding-left: $ALTERNATING_CONTENT_MARGIN_TABLET;
                }
                @include breakpoint-desktop-up {
                    padding-left: $ALTERNATING_CONTENT_MARGIN_DESKTOP;
                }
            }
        }
    }
}
