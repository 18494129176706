.Search {

    &-form {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: $SPACE_MEDIUM;
        background-color: $COLOR_LIGHT_GRAY;
        padding: $SPACE_LARGE;
    }
    // Fighting specificity with legacy styles
    &-text[type="text"] {
        margin: 0;
        border: 0;
        border-bottom: solid 1px $COLOR_DARK_GRAY;
        border-radius: 0;
        background: transparent;
        &::placeholder {
            color: $COLOR_DARKER_GRAY;
        }
    }
    &-submit {
        position: relative;
        background-color: #1C4D6D;
        border-radius: 50%;
        width: 40px;
        height: 40px;
    }
    &-submitIcon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        filter: invert(1);
    }
}
