@import "../../utils/mixins";

.PriceTag {
    position: relative;
    display: inline-grid;
    grid-template-columns: auto 1px auto;
    align-items: center;
    gap: $SPACE_MEDIUM;
    padding: $SPACE_MEDIUM;

    background: var(--color-button-background, $COLOR_BUTTON_PRIMARY_BACKGROUND);
    border: solid 1px var(--color-button-border, $COLOR_BUTTON_PRIMARY_BORDER);
    border-radius: $BORDER_RADIUS_BUTTON;

    font-family: var(--font-family-button, $FONT_FAMILY_BUTTON);
    line-height: 1;
    color: var(--color-button-text, $COLOR_BUTTON_PRIMARY_TEXT);

    transition: $TRANSITION_COLOR_BUTTON;

    cursor: pointer;

    &:not(.is-disabled),
    &:not(.is-purchased) {
        &:hover,
        &:active,
        &:focus {
            filter: brightness(1.3);
            //background-color: #C4FFC1;
        }
    }

    &-content {

    }
    &-price {
        font-weight: 700;
        display: flex;
        align-items: center;
    }
    &-plat {
        display: none;
        margin-right: $SPACE_SMALLER;
    }
    &-sale {
        text-decoration: line-through;
    }
    &-divider {
        width: 1px;
        background-color: var(--color-button-text, $COLOR_BUTTON_PRIMARY_TEXT);
        margin: -$SPACE_SMALLER 0;
        height: calc(100% + $SPACE_SMALL);
    }
    &-label, &-purchased, &-disabled {
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
    }
    &-purchased, &-disabled {
        display: none;
    }

    &.is-disabled, &.is-purchased {
        grid-template-columns: auto;
        background-color: $COLOR_BUTTON_DISABLED_BACKGROUND !important;
        color: $COLOR_BUTTON_DISABLED_TEXT;
        padding: $SPACE_MEDIUM 40px;
        cursor: unset;

        .PriceTag-content,
        .PriceTag-divider {
            display: none;
        }
        .PriceTag-label {
            display: none;
        }
    }
    &.is-disabled {
        .PriceTag-disabled {
            display: block;
        }
    }
    &.is-purchased {
        .PriceTag-purchased {
            display: block;
        }
    }

        &--large {
        .PriceTag-price {
            font-size: 28px;
        }
        .PriceTag-label {
            font-size: 28px;
        }
    }

    &.plat-currency {
        .PriceTag-content {
            position: relative;
        }
        .PriceTag-plat {
            display: inline-block;
        }
        &.PriceTag--large {
            .PriceTag-plat {
                width: 24px;
                height: 24px;
                margin-right: $SPACE_SMALL;
            }
        }

    }
}
