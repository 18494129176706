.Duviri {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;

    &-svg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
}
