.Lightbox {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: rgba(black, 0.7);

    z-index: $Z_INDEX_MODAL;

    &-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
    }
    &-slides {
        position: relative;
    }
    &-slide {
        position: relative;
        display: none;
        text-align: center;

        &.is-active {
            display: block;
        }
    }
    &-slideImage {
        display: inline-block;
        max-width: 100%;
        height: auto;
        object-fit: contain;
        vertical-align: bottom;
    }
    &-slideLink {
        position: absolute;
        bottom: $SPACE_LARGE;
        left: 50%;
        transform: translateX(-50%);
        display: flex;

        flex-flow: row nowrap;
        align-items: center;
        gap: $SPACE_SMALLER;

        padding: $SPACE_SMALL $SPACE_MEDIUM;
        border-radius: $SPACE_SMALLER;
        background-color: rgba(black, 0.9);
        color: white;
        line-height: 1;
        --color-link: white;
        --color-link-hover: white;

        .Icon {
            filter: invert(1);
        }
    }
    &-prev,
    &-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        padding: $SPACE_MEDIUM;
        z-index: 1;

        @include breakpoint-mobile-only {
            background-color: rgba(black, 0.5);
            border-radius: 50%;
            padding: $SPACE_SMALL;
        }
    }
    &-prevIcon,
    &-nextIcon {
        width: 32px;
        height: 32px;
        filter: invert(1);
        @include breakpoint-desktop-up {
            width: 48px;
            height: 48px;
        }
    }
    &-prev {
        right: 100%;
        @include breakpoint-mobile-only {
            transform: translate(25%, -50%);
        }
    }
    &-next {
        left: 100%;
        @include breakpoint-mobile-only {
            transform: translate(-25%, -50%);
        }
    }
    &-close {
        position: absolute;
        top: 0;
        right: 0;
        padding: $SPACE_SMALL;
        cursor: pointer;
    }
    &-closeIcon {
        width: 32px;
        height: 32px;
        filter: invert(1);
        @include breakpoint-tablet-up {
            width: 48px;
            height: 48px;
        }
    }

    &.is-active {
        display: block;
    }
}
