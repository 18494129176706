.Caption {
    position: relative;
    text-align: center;

    &-content {

    }
    &-image {
        margin: 0;
        padding: 0;
    }
    &-text {
    }
}
