.ButtonGroup {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    gap: $SPACE_MEDIUM;

    @include breakpoint-mobile-only {
        flex-flow: column nowrap;
        align-items: stretch;

        .Button {
            width: 100%;
        }
    }
}
