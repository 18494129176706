.FormInput {
    position: relative;

    &[type="text"],
    &[type="password"] {
        @include FORM_FIELD_STYLES;
    }

    &[type="checkbox"],
    &[type="radio"] {
        display: none;
        &:checked {
            &+ .FormInputCheckbox,
            &+ .FormInputRadio {
                background-color: $COLOR_FORM_BACKGROUND_CHECKBOX;
                &:after {
                    display: block;
                }
            }
        }
    }
}
.FormInputLabel {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: $SPACE_SMALL;
    font-family: var(--font-family-form, $FONT_FAMILY_FORM);
    font-size: $FONT_SIZE_FORM_LABEL;
    line-height: $LINE_HEIGHT_FORM_LABEL;
}
.FormInputCheckbox,
.FormInputRadio {
    position: relative;
    display: inline-block;
    width: $SIZE_FORM_CHECKBOX;
    height: $SIZE_FORM_CHECKBOX;
    @include FORM_BASIC_STYLES(true);
    &:after {
        content: '';
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.FormInputCheckbox {
    &:after {
        width: $SIZE_FORM_CHECKBOX_ICON;
        height: $SIZE_FORM_CHECKBOX_ICON;
        background-image: url(/images/icons/material/check.svg);
        background-size: $SIZE_FORM_CHECKBOX_ICON $SIZE_FORM_CHECKBOX_ICON;
        background-repeat: no-repeat;
        background-position: center;
        filter: invert(1);
    }
}
.FormInputRadio {
    border-radius: 50%;
    &:after {
        width: $SIZE_FORM_RADIO_FILL;
        height: $SIZE_FORM_RADIO_FILL;
        background-color: $COLOR_FORM_CHECKBOX;
        border-radius: 50%;
    }
}

.FormInputWrapper {
    @include FORM_FIELD_WRAPPER_STYLES;

    &.is-icon {
        .FormInput {
            padding-left: $SPACE_MEDIUM + $SIZE_ICON + $SPACE_SMALL;
        }

        .FormInputWrapper-content {
            &:before {
                @include FORM_FIELD_ICON_STYLES;
            }
        }
    }
}

.customRadio {
    display: block;
    padding: 16px 24px;
    border-radius: 8px;
    //border: 1px solid rgba(187, 37, 48, 1);
    background-color: rgba(50, 50, 50, 1);
    margin-bottom: 16px;

    .FormInputLabel-text {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-family: 'Roboto';
        font-size: 18px;
        line-height: 28.8px;
        font-weight: 400;
    }

    .FormInputRadio {

        background-color: rgba(50, 50, 50, 1);
        border: 1px solid rgba(151, 151, 151, 1);
        &::after {
            background-color: rgba(187, 37, 48, 1);
        }

        @include breakpoint-mobile-only {
            margin-top: 5px;
        }
    }

    .FormInput[type=radio]:checked + .FormInputRadio {
        border: 1px solid rgba(187, 37, 48, 1);
        background-color: rgba(187, 37, 48, 1);

        @include breakpoint-mobile-only {
            margin-top: 5px;
        }
    }
}

.customRadio:has(input[type="radio"]:checked) {
    border: 1px solid rgba(187, 37, 48, 1);
    background-color: rgba(64, 64, 64, 1);
}
