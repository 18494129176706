.Section {
    position: relative;
    scroll-margin-top: var(--page-header-height);

    &-wrapper {
        position: relative;
        overflow-x: hidden;
    }

    &-background {
        @include FULL_COVER;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    &-content {
        position: relative;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint-mobile-only {
            width: $BREAKPOINT_MOBILE;
        }
        @include breakpoint-tablet-only {
            width: $BREAKPOINT_TABLET;
        }
        @include breakpoint-desktop-only {
            width: $BREAKPOINT_DESKTOP;
        }
        @include breakpoint-wide-up {
            width: $BREAKPOINT_WIDE;
        }
    }

    &--masthead {
        .Section-background {
            overflow: hidden;
            background-color: $COLOR_INVERTED_BACKGROUND;

            @include breakpoint-wide-up {
                background-size: 2200px auto;

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 50%;
                    width: 2202px;
                    transform: translateX(-50%);
                    background: linear-gradient(to right, $COLOR_INVERTED_BACKGROUND 0, transparent 201px, transparent 2001px, $COLOR_INVERTED_BACKGROUND 100%)
                }
            }
        }
    }
    &--widescreenBackground {
        @include breakpoint-wide-up {
            .Section-background {
                left: 50%;
                right: auto;
                width: 1600px;
                transform: translateX(-50%);

                @include MASK_WIDESCREEN_FADE;
            }
        }
    }
    &--left {
        .Section-background {
            @include breakpoint-wide-up {
                background-size: 2200px auto;

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 50%;
                    width: 2202px;
                    transform: translateX(-50%);
                    background: linear-gradient(to right, $COLOR_BACKGROUND 0, transparent 201px, transparent 2001px, $COLOR_BACKGROUND 100%)
                }
            }
        }
    }
}
