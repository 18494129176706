.FormFile {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    background-color: $COLOR_FORM_FILE_BACKGROUND;
    border: dashed 1px $COLOR_FORM_FILL;
    padding: $SPACE_LARGE $SPACE_LARGER;
    text-align: center;
    cursor: pointer;

    &-input {
        display: none;
    }
    &-icon {
        width: $SIZE_FORM_FILE_ICON;
        height: $SIZE_FORM_FILE_ICON;
        opacity: 0.65;
    }
    &-title {
        font-weight: bold;
        color: $COLOR_FORM_FILE_TEXT;
    }
    &-button {
        background-color: $COLOR_FORM_BACKGROUND;
        border: solid 1px $COLOR_FORM_BORDER;
        color: $COLOR_FORM_FILL;
        font-size: $FONT_SIZE_FORM;
        margin: $SPACE_SMALL 0;
        padding: $SPACE_THIN $SPACE_LARGE;
        transition: all 250ms;
    }
    &-subtext {
        color: $COLOR_FORM_FILL;
        font-size: $FONT_SIZE_FORM_LABEL;
    }
    &-error {
        @include FORM_FIELD_ERROR_STYLES;
    }

    &:hover {
        .FormFile-button {
            background-color: $COLOR_FORM_BORDER;
            color: $COLOR_FORM_FILE_TEXT_HOVER;
        }
    }
}
