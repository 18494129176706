.Tile {
    position: relative;

    &-image {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    &--default {
        .Tile-text {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: center;
        }
    }

    &--captioned {
        display: grid;
        grid-template-rows: auto 1fr;

        .Tile-text {
            text-align: center;
        }
    }
}
