.Slideshow {
    position: relative;

    &--background {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.SlideshowSlide {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    opacity: 0;
    transition: opacity 1s;

    background: center / cover no-repeat;

    &.is-active {
        opacity: 1;
    }
}
