.TabContainer {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    gap: $SPACE_MEDIUM;
    align-items: center;

    margin-bottom: 36px;

    @include breakpoint-desktop-up {
        margin-bottom: 40px;
    }

    &-compact {
        position: relative;
        @include breakpoint-desktop-up {
            display: none;
        }
    }
    &-dropdown {
        margin: 0;
        background: var(--color-tab-dropdown-background, $COLOR_TAB_DROPDOWN_BACKGROUND);
        border: solid 1px var(--color-tab-dropdown-border, $COLOR_TAB_DROPDOWN_BORDER);
        border-radius: $BORDER_RADIUS_TAB;
        color: var(--color-tab-dropdown-text, $COLOR_TAB_DROPDOWN_TEXT);
    }
    &-tabs {
        position: relative;
        display: none;
        gap: $SPACE_MEDIUM;

        @include breakpoint-desktop-up {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &.is-labeled {
        grid-template-columns: auto 1fr;
    }

    &--centered {
        .TabContainer {
            &-tabs {
                @include breakpoint-desktop-up {
                    justify-content: center;
                }
            }
        }
    }
}
.Tab {
    position: relative;
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    background: var(--color-tab-background, $COLOR_TAB_BACKGROUND);
    border: solid 1px var(--color-tab-border, $COLOR_TAB_BORDER);
    border-radius: $BORDER_RADIUS_TAB;
    min-height: $SIZE_TAB_HEIGHT;
    padding: $PADDING_TAB;

    color: transparent;
    transition: $TRANSITION_TAB;

    font-family: var(--font-family-tab, $FONT_FAMILY_BUTTON);
    font-size: $FONT_SIZE_BUTTON;
    font-weight: $FONT_WEIGHT_TAB_HOVER;
    text-transform: $TEXT_TRANSFORM_BUTTON;

    cursor: pointer;

    &:after {
        content: attr(data-label);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        color: var(--color-tab-text, $COLOR_BUTTON_PRIMARY_TEXT);
        transition: $TRANSITION_TAB;
        font-weight: $FONT_WEIGHT_TAB;
    }

    &.is-active {
        background: var(--color-tab-background-active, $COLOR_TAB_BACKGROUND_ACTIVE);
        border-color: var(--color-tab-border-active, $COLOR_TAB_BORDER_ACTIVE);
        cursor: default;

        &:after {
            color: var(--color-tab-text-active, $COLOR_TAB_TEXT_ACTIVE);
            font-weight: $FONT_WEIGHT_TAB_HOVER;
        }
    }
    &:hover {
        background: var(--color-tab-background-hover, $COLOR_TAB_BACKGROUND_HOVER);
        border-color: var(--color-tab-border-hover, $COLOR_TAB_BORDER_HOVER);

        &:after {
            color: var(--color-tab-text-hover, $COLOR_TAB_TEXT_HOVER);
            font-weight: $FONT_WEIGHT_TAB_HOVER;
        }
    }
}

.TabContent {
    display: none;
    position: relative;

    &.is-active {
        display: block;
    }
}
