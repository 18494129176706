.AnimateWhenVisible {
    position: relative;
    overflow: visible;

    &-content {
        position: relative;
    }

    &.is-visible {
    }

    &--fade {
        opacity: 0;
        transition: all 1s;
        pointer-events: none;

        &.is-visible {
            opacity: 1;
            pointer-events: all;
        }
    }

    &--parallaxFade {
        opacity: 0;
        transform: translateY(2rem);
        transition: all 1s ease-out;
        pointer-events: none;

        &.is-visible {
            opacity: 1;
            transform: translateY(0);
            pointer-events: all;
        }
    }

    &--zoomFade {
        opacity: 0;
        transform: scale(0.75);
        transition: all 1s ease-out;
        pointer-events: none;

        &.is-visible {
            opacity: 1;
            transform: scale(1.0);
            pointer-events: all;
        }
    }
}
