.Chart {
    position: relative;
    display: inline-block;

    canvas {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
