@use 'sass:math';

.Filigree {
    $FULL_WIDTH: 105px;
    $FULL_HEIGHT: 505px;
    $TABLET_HEIGHT: $FULL_HEIGHT * 0.538;
    $MOBILE_HEIGHT: $FULL_HEIGHT * 0.308;

    @include FULL_COVER;

    overflow: hidden;

    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 80px;
        bottom: 0;
        width: $FULL_WIDTH;
        background: top / 100% $FULL_HEIGHT no-repeat url(/images/filigree.svg);

        /*
        @include breakpoint-tablet-only {
            width: 56px;
            background-size: 100% $TABLET_HEIGHT;
        }
        @include breakpoint-mobile-only {
            width: 32px;
            background-size: 100% $MOBILE_HEIGHT;
        }
        */
    }
    &:before {
        right: max(100% - #{$FULL_WIDTH}, 50% + #{$GRID_GAP + math.div($CONTENT_WIDTH_MOBILE, 2)});

        @include breakpoint-tablet-up {
            right: max(100% - #{$FULL_WIDTH}, 50% + #{$GRID_GAP + math.div($CONTENT_WIDTH_TABLET, 2)});
        }
        @include breakpoint-desktop-up {
            right: max(100% - #{$FULL_WIDTH}, 50% + #{$GRID_GAP + math.div($CONTENT_WIDTH_DESKTOP, 2)});
        }
        @include breakpoint-wide-up {
            right: max(100% - #{$FULL_WIDTH}, 50% + #{$GRID_GAP + math.div($CONTENT_WIDTH_WIDE, 2)});
        }
    }
    &:after {
        transform: scaleX(-1);

        left: max(100% - #{$FULL_WIDTH}, 50% + #{$GRID_GAP + math.div($CONTENT_WIDTH_MOBILE, 2)});

        @include breakpoint-tablet-up {
            left: max(100% - #{$FULL_WIDTH}, 50% + #{$GRID_GAP + math.div($CONTENT_WIDTH_TABLET, 2)});
        }
        @include breakpoint-desktop-up {
            left: max(100% - #{$FULL_WIDTH}, 50% + #{$GRID_GAP + math.div($CONTENT_WIDTH_DESKTOP, 2)});
        }
        @include breakpoint-wide-up {
            left: max(100% - #{$FULL_WIDTH}, 50% + #{$GRID_GAP + math.div($CONTENT_WIDTH_WIDE, 2)});
        }
    }

    &--straight {
        &:before,
        &:after {
            top: 0;
            background-image: url(/images/filigree-straight.svg);
        }
    }
}
