.SubNavigation {
    position: relative;
    text-align: center;
    a, a:hover, a:active {
        text-decoration: none;
    }

    &-wrapper {
        position: relative;
        background-color: var(--color-subnavigation-background, #{$COLOR_INVERTED_BACKGROUND});
    }
    &-scroller {
        position: relative;
        overflow-x: auto;
        scroll-behavior: smooth;
        padding: 0 $SPACE_MEDIUM;
    }

    &-content {
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        white-space: nowrap;
    }

    &-left,
    &-right {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 44px;
        cursor: pointer;
    }
    &-left {
        left: 0;
        background: linear-gradient(to right, var(--color-subnavigation-background, #{$COLOR_INVERTED_BACKGROUND}), transparent);
    }
    &-right {
        right: 0;
        background: linear-gradient(to left, var(--color-subnavigation-background, #{$COLOR_INVERTED_BACKGROUND}), transparent);
    }
    &-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &-underline {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 4px;
        background-color: var(--color-subnavigation-underline, #{$COLOR_GOLD});
        &.is-animated {
            transition: all $TRANSITION_FAST;
        }
    }

    &.is-fixed {
        .SubNavigation-wrapper {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 100;
        }
    }
    &.is-collapsed {
        .SubNavigation-left,
        .SubNavigation-right {
            display: block;
        }
    }
}
