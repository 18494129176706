.DeferredBlock {
    opacity: 1;
    transition: opacity 250ms;

    &-inner {
        position: relative;
    }

    &.is-hidden {
        display: none;
        opacity: 0;
    }
    &.is-animating {
        pointer-events: none;

        .DeferredBlock-inner {
            position: absolute;
        }
    }
    &.is-moving {
        .DeferredBlock-inner {

        }
    }
    &.is-fading {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        opacity: 0;
        .DeferredBlock-inner {

        }
    }
}
