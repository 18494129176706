.SubNavigationDivider {
    position: relative;
    padding-left: $SPACE_MEDIUM;
    padding-right: $SPACE_MEDIUM;

    &-inner {
        width: $SPACE_MINIMUM;
        height: 1em;
        background-color: var(--color-subnavigation-divider, #{rgba($COLOR_WHITE, 0.5)});
    }
}
