.Card {
    position: relative;
    display: grid;
    gap: 0;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    background: var(--card-background, transparent);

    &-media {
        position: relative;
        display: block;
        aspect-ratio: 16/9;
        padding-bottom: 56.25%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    &-mediaContainer {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    &-content {
        border: solid 1px var(--card-border-color, #{$COLOR_LIGHT_GRAY});
        border-top: 0;
        padding: $CARD_CONTENT_PADDING;
    }

    &--ability {
        .Card-media {
            aspect-ratio: 1;
            padding-bottom: 100%;
        }
    }
}
