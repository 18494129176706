.CollapsibleActivator {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    gap: $GRID_GAP;
}

.CollapsibleActivatorItem {
    position: relative;
    background: var(--collapsible-background, #{$COLOR_BACKGROUND});
    border: var(--collapsible-border, none);
    color: var(--collapsible-text-color, #{$COLOR_TEXT});
    transition: all $TRANSITION_FAST;
    cursor: pointer;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: $SPACE_SMALLER;
        background: var(--collapsible-margin-background, transparent);
        transition: all $TRANSITION_FAST;
        pointer-events: none;
    }

    &-title {
        position: relative;
        color: var(--collapsible-title-color, #{$COLOR_TITLE});
        padding: var(--collapsible-title-padding, 24px 24px 24px 32px);
        @include TYPOGRAPHY_H4_ALTERNATE;
    }
    &-wrapper {
        position: relative;
        transition: height $TRANSITION_FAST;
        overflow: hidden;
    }
    &-content {
        position: relative;
        padding: var(--collapsible-content-padding, 0 24px 32px 32px);
    }

    &.is-active {
        &:after {
            background: var(--collapsible-margin-background-active, #{$COLOR_ACCENT});
        }
    }

    &:not(.is-active) {
        .CollapsibleActivatorItem-wrapper {
            height: 0 !important;
        }
    }
}
