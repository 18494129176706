.SectionContent {
    @include RESPONSIVE_CONTENT_MARGINS;

    p:not(:first-child) {
        margin-block-start: 1.5rem;
    }
    p:not(:last-child) {
        margin-block-end: 1.5rem;
    }

    // Masthead (deprecated, should use Masthead component instead)
    &--masthead {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-end;
    }

    // Standard vertical section padding
    &--padded {
        @include STANDARD_SECTION_PADDING;
    }

    // Single Column (left)
    &--singleColumnLeft {
        margin-right: auto;
        max-width: calc(640px + 10vw);
        @include breakpoint-wide-up {
            max-width: 800px;
        }
    }
    // Single Column (right)
    &--singleColumnRight {
        margin-left: auto;
        max-width: calc(640px + 10vw);
        @include breakpoint-wide-up {
            max-width: 800px;
        }
    }

    &--twoColumn {
        display: grid;
        grid-template-columns: minmax(0, 1fr);

        @include breakpoint-tablet-up {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
}
