.Icon {
    display: inline-block;
    height: $SIZE_ICON;
    width: $SIZE_ICON;

    &--raster {
        height: $SIZE_ICON_RASTER;
        width: $SIZE_ICON_RASTER;
    }

    &.is-inverted {
        filter: invert(1);
    }
    &.is-inline {
        vertical-align: text-top;
    }
}
