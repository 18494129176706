.Button {
    @include BUTTON_STYLES;

    &-icon {
        margin-left: -$SPACE_SMALL;
        margin-right: $SPACE_SMALL;
        filter: var(--filter-button-icon, invert(1));
    }
    &-iconAfter {
        margin-left: $SPACE_SMALL;
        margin-right: -$SPACE_SMALL;
        filter: var(--filter-button-icon, invert(1));
    }
    &-label {
        display: inline-block;
    }

    &:hover,
    &:active,
    &:focus {
        .Button-icon {
            filter: var(--filter-button-icon-hover, invert(1));
        }
    }

    &--primary {
        @include BUTTON_PRIMARY_COLORS;
    }
    &--secondary {
        @include BUTTON_SECONDARY_COLORS;
    }
    &--invertedSecondary {
        @include BUTTON_SECONDARY_INVERTED_COLORS;
    }
    &--disabled {
        @include BUTTON_SECONDARY_DISABLED_COLORS;
        pointer-events: none;
    }

}
