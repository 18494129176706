html {
    @include TYPOGRAPHY_BASE;

    @include breakpoint-tablet-up {
        --page-header-height: #{$HEADER_TABLET_HEIGHT};
    }
    @include breakpoint-desktop-up {
        --page-header-height: #{$HEADER_DESKTOP_HEIGHT};
    }
}
body {
    --font-family-body: #{$FONT_FAMILY_BODY};
    --font-family-title: #{$FONT_FAMILY_TITLE};
    --font-family-subtitle: #{$FONT_FAMILY_BODY};
    --font-family-form: #{$FONT_FAMILY_FORM};
    --font-family-button: #{$FONT_FAMILY_BUTTON};
    --color-title: #{$COLOR_TITLE};
    --color-title-inverted: #{$COLOR_INVERTED_TITLE};
    --color-text: #{$COLOR_TEXT};
    --color-text-inverted: #{$COLOR_INVERTED_TEXT};
    --page-header-height: #{$HEADER_MOBILE_HEIGHT};

    --color-link: #{$COLOR_LINK};
    --color-link-hover: #{$COLOR_LINK_HOVER};

    background-color: $COLOR_BACKGROUND;
    color: var(--color-text, #{$COLOR_TEXT});
    @include TYPOGRAPHY_BASE;

    @include breakpoint-tablet-up {
        --page-header-height: #{$HEADER_TABLET_HEIGHT};
    }
    @include breakpoint-desktop-up {
        --page-header-height: #{$HEADER_DESKTOP_HEIGHT};
    }
}

body, div, p, h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

h1,h2,h3,h4,h5,h6 {
    margin: 0;
    padding: 0;
}

h1, .h1 {
    @include TYPOGRAPHY_H1;
}
h2, .h2 {
    @include TYPOGRAPHY_H2;
}
h3, .h3 {
    @include TYPOGRAPHY_H3;
}
h4, .h4 {
    @include TYPOGRAPHY_H4;
}
h5, .h5 {
    @include TYPOGRAPHY_H5;
}
h6, .h6 {
    @include TYPOGRAPHY_H6;
}
.h1alt {
    @include TYPOGRAPHY_H1_ALTERNATE;
}
.h2alt {
    @include TYPOGRAPHY_H2_ALTERNATE;
}
.h3alt {
    @include TYPOGRAPHY_H3_ALTERNATE;
}
.h4alt {
    @include TYPOGRAPHY_H4_ALTERNATE;
}
.h5alt {
    @include TYPOGRAPHY_H5_ALTERNATE;
}
.h6alt {
    @include TYPOGRAPHY_H6_ALTERNATE;
}
.hype {
    @include TYPOGRAPHY_HYPE;
}

h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6,
.h1alt,.h2alt,.h3alt,.h4alt,.h5alt,.h6alt {
    color: var(--color-title);
    &.is-inverted { color: var(--color-title-inverted); }
}

p {
    @include TYPOGRAPHY_PARAGRAPH;

    &.small {
        @include TYPOGRAPHY_PARAGRAPH_SMALL;
    }
    &.large {
        @include TYPOGRAPHY_PARAGRAPH_LARGE;
    }
}

li {
    margin-top: $MARGIN_LIST_BASE;
    margin-bottom: $MARGIN_LIST_BASE;
}

ul, ol {
    padding-inline-start: 1rem;
    @include TYPOGRAPHY_PARAGRAPH;

    &.small {
        @include TYPOGRAPHY_PARAGRAPH_SMALL;
    }
    &.large {
        @include TYPOGRAPHY_PARAGRAPH_LARGE;
    }
}

a {
    color: var(--color-link, $COLOR_LINK);
    transition: $TRANSITION_FAST;

    &.is-unlined {
        text-decoration: none;
    }

    &:visited {
        color: var(--color-link, $COLOR_LINK);
    }

    &:hover,
    &:active {
        color: var(--color-link-hover, $COLOR_LINK_HOVER);
    }
    &:not(.is-unlined) {
        &:hover,
        &:active {
            text-decoration: underline;
        }
    }

    &.is-inverted {
        --color-link: #{$COLOR_LINK_INVERTED};
        --color-link-hover: #{$COLOR_LINK_INVERTED};
    }
}

input[type="button"],
input[type="submit"] {
    @include BUTTON_STYLES;
}
// Split due to SASS deprecation
input[type="button"],
input[type="submit"] {
    margin: 0; // Removes margins added by Skeleton. Remove when no longer needed
}
input[type="button"] {
    @include BUTTON_SECONDARY_COLORS;
}
input[type="submit"] {
    @include BUTTON_PRIMARY_COLORS;
}
.untransformed {
    text-transform: none;
}
.uppercase {
    text-transform: uppercase;
}
.capitalize {
    text-transform: capitalize;
}

.inverted-text {
    color: var(--color-text-inverted, #{$COLOR_INVERTED_TEXT});

    h1,h2,h3,h4,h5,h6,
    .h1,.h2,.h3,.h4,.h5,.h6,
    .h1alt,.h2alt,.h3alt,.h4alt,.h5alt,.h6alt {
        color: var(--color-text-inverted, #{$COLOR_INVERTED_TEXT});
    }
}

.header-spacer-fixed {
    height: $HEADER_MOBILE_HEIGHT;
    @include breakpoint-tablet-up {
        height: $HEADER_TABLET_HEIGHT;
    }
    @include breakpoint-desktop-up {
        height: $HEADER_DESKTOP_HEIGHT;
    }
}

.header-spacer {
    height: var(--page-header-height, 0);
}

.header-masthead-spacer {
    @include breakpoint-tablet-up {
        height: var(--page-header-height, 0);
    }
}
