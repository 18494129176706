.CollapsibleFAQ {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    align-items: center;
    padding: 0;

    background: var(--faq-background, #{$COLOR_EXTREMELY_DARK_GRAY});
    border: var(--faq-border, none);
    border-radius: var(--faq-border-radius, 0);
    color: var(--faq-color-text, #{$COLOR_LIGHT_GRAY});
    cursor: pointer;

    &-header {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        background: var(--faq-background-header, transparent);
    }
    &-toggle {
        position: relative;
        width: 16px;
        height: 16px;
        margin: 12px;
    }
    &-open {
        &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: 0;
            bottom: 0;
            width: 2px;
            transform: translateX(-50%);
            background-color: var(--faq-color-toggle, #{$COLOR_EXTREMELY_DARK_GRAY});
        }
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            height: 2px;
            transform: translateY(-50%);
            background-color: var(--faq-color-toggle, #{$COLOR_EXTREMELY_DARK_GRAY});
        }
    }
    &-close {
        display: none;
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            height: 2px;
            transform: translateY(-50%);
            background-color: var(--faq-color-toggle-active, #{$COLOR_WHITE});
        }
    }
    &-question {
        font-weight: 700;
        font-size: 12px;
        line-height: 1.5;

        margin: $SPACE_SMALL $SPACE_MEDIUM $SPACE_SMALL 0;

        @include breakpoint-tablet-up {
            font-size: 14px;
        }
        @include breakpoint-desktop-up {
            font-size: 16px;
        }
    }
    &-answer {
        position: relative;
        overflow: hidden;
        height: 0;
        transition: height 200ms ease-out;
    }
    &-answerInner {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: $SPACE_MEDIUM;
    }

    &.is-open {
        .CollapsibleFAQ-header {
            background: var(--faq-background-header-active, #{$COLOR_VERY_DARK_GRAY});
            color: var(--faq-color-header-active, #{$COLOR_WHITE});
        }
        .CollapsibleFAQ-open {
            display: none;
        }
        .CollapsibleFAQ-close {
            display: block;
        }
    }

    &:hover {
        .CollapsibleFAQ-header {
            background: var(--faq-background-header-active, #{$COLOR_VERY_DARK_GRAY});
            color: var(--faq-color-header-active, #{$COLOR_WHITE});
        }

        .CollapsibleFAQ-open {
            &:before,
            &:after {
                background-color: var(--faq-color-toggle-active, #{$COLOR_WHITE});
            }
        }
    }
}
