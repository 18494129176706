.MobileScroller {
    position: relative;
    overflow-x: hidden;

    &-inner {
        position: relative;
        overflow-x: auto;
    }

    &:before, &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 44px;
        z-index: 1;
        pointer-events: none;
    }
    &.is-fadedLeft {
        &:before {
            content: "";
            left: 0;
            background: linear-gradient(to right, $COLOR_BACKGROUND, transparent);
        }
    }
    &.is-fadedRight {
        &:after {
            content: "";
            right: 0;
            background: linear-gradient(to left, $COLOR_BACKGROUND, transparent);
        }
    }
    
    &--inset {
        @include RESPONSIVE_CONTENT_MARGINS;
    }
}
